<template>
  <div id="app">
    <theme-provider
      :theme="themeTailwind"
      class="hyphens-auto font-sans font-light"
    >
      <the-notification class="z-50 fixed bottom-12 inset-x-2 max-w-2xl mx-auto" />
      <router-view />
      <div class="relative max-w-screen-lg">
        <div class="fixed m-4 right-0 left-0 sm:left-auto bottom-0 z-[200]">
          <chatbot :organization="organizationId ? organization : undefined" />
        </div>
      </div>
    </theme-provider>
  </div>
</template>

<script>
  import Theme from './services/codefabrik-ui/theme'
  import TheNotification from '@/components/notification/the-notification.vue'
  import axios from '@/services/axios/axios'
  import Chatbot from '@/components/chatbot/chatbot.vue'
  import { useQuery } from '@/mixins'
  import { organizationManagementService } from '@/api'

  export default {
    name: 'App',
    components: { Chatbot, TheNotification },
    computed: {
      themeTailwind () {
        return Theme
      }
    },
    data() {
      return {
        organizationId: undefined
      }
    },
    created () {
      document.documentElement.lang = navigator.language
      this.$router.afterEach((to, from) => {
        this.organizationId = to.params.organizationId
        if (to.name === from.name) return
        this.$store.commit('setBreadcrumbs', null)
        this.$store.commit('setSidebar', null)
      })
    },
    mixins: [
      useQuery({
        name: 'organization',
        serviceFn: organizationManagementService.organization,
        fetchPolicy: 'cache-first',
        variables() {
          return { input: { organizationId: this.organizationId } }
        },
        skipIf: function () { return !this.organizationId },
        watchVariables: true
      }),],
  }
</script>
