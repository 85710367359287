<template>
  <svg-loading
    v-if="loading"
    class="h-16 w-16 mx-auto"
  />
  <div
    v-else
  >
    <slot />
  </div>
</template>

<script>
  import SvgLoading from '@/components/svg/svg-loading'

  export default {
    components: { SvgLoading },
    props: {
      loading: {
        type: Boolean,
        default: true
      }
    }
  }
</script>
