const common = {
  absences: 'Fehlzeiten',
  all: 'Alle',
  annualLeave: 'Urlaubsanspruch pro Jahr',
  attendanceMonitors: 'Anwesenheitsmonitore',
  audits: 'Überprüfungen',
  budget: 'Budget',
  clockIn: 'Kommen',
  clockOut: 'Gehen',
  comment: 'Kommentar',
  confirmPassword: 'Passwort bestätigen',
  contactPerson: 'Kontaktperson',
  create: 'Anlegen',
  currentPassword: 'Aktuelles Passwort',
  customer: 'Kunde',
  customerAddress: 'Adresse',
  customerAnalysis: 'Kundenanalyse',
  customerName: 'Name',
  customers: 'Kunden',
  description: 'Beschreibung',
  deviceName: 'Gerätename',
  distributable: 'Verteilbar',
  distribute: 'Verteilen',
  distributed: 'Vollständig verteilt',
  distributions: 'Zeitverteilungen',
  distributedTimes: 'Verteilte Zeiten',
  download: 'Herunterladen',
  employee: 'Mitarbeiter',
  end: 'Ende',
  forgotPassword: 'Passwort vergessen?',
  holiday: 'Feiertag',
  hourlyRate: 'Stundensatz',
  invoicableTime: 'Abrechenbar',
  invoice: 'Rechnung',
  invoiceLineItems: 'Rechnungspositionen',
  invoicedTime: 'Abgerechnet',
  invoiceNumber: 'Rechnungsnummer',
  invoices: 'Rechnungen',
  invoiceTemplate: 'Rechnungsvorlage',
  invoiceTemplates: 'Rechnungsvorlagen',
  leave: 'Urlaub',
  leaveAccount: 'Urlaubskonto',
  leaveAgreements: 'Urlaubsvereinbarungen',
  leaveAndTimeAccounts: 'Urlaubs- und Zeitkonten',
  leaves: 'Urlaube',
  logout: 'Ausloggen',
  members: 'Mitglieder',
  name: 'Name',
  newPassword: 'Neues Passwort',
  number: 'Nummer',
  order: 'Auftrag',
  orderName: 'Name',
  orders: 'Aufträge',
  orderAnalysis: 'Auftragsanalyse',
  organization: 'Organisation',
  organizationalUnit: 'Organisationseinheit',
  organizationalUnits: 'Organisationseinheiten',
  organizations: 'Organisationen',
  overtime: 'Überstunden',
  password: 'Passwort',
  publicHolidays: 'Gesetzliche Feiertage',
  recordedTimes: 'Erfasste Zeiten',
  recordings: 'Zeiterfassungen',
  remainingLeave: 'Resturlaub',
  requestLeave: 'Urlaub beantragen',
  role: 'Rolle',
  security: 'Sicherheit',
  sickNote: 'Krankmeldung',
  sickNotes: 'Krankmeldungen',
  signIn: 'Anmelden',
  signUp: 'Registrieren',
  staff: 'Personal',
  start: 'Start',
  status: 'Status',
  submitSickNote: 'Krankmeldung einreichen',
  supervisor: 'Vorgesetzter',
  takenLeave: 'Verwendeter Urlaub',
  targetLeave: 'Urlaubsanspruch',
  tasks: 'Aufgaben',
  time: 'Zeit',
  timeAccount: 'Zeitkonto',
  timeRecord: 'Zeiterfassung',
  toDos: 'To-dos',
  upcomingHolidays: 'Kommende Feiertage',
  vat: 'USt',
  workingTime: 'Arbeitszeit',
  workingTimeAgreements: 'Arbeitszeitvereinbarungen',
  workingWeek: 'Arbeitswoche',
}

export default {
  breadcrumb: {
    absences: common.absences,
    attendanceMonitor: 'Anwesenheitsmonitor',
    attendanceMonitors: common.attendanceMonitors,
    create: common.create,
    customer: common.customer,
    customerAnalysis: common.customerAnalysis,
    customers: common.customers,
    distributableDay: 'Verteilbarer Tag',
    distribute: 'Verteilen',
    distributedTimes: common.distributedTimes,
    distributions: common.distributions,
    download: common.download,
    holiday: common.holiday,
    home: 'Home',
    invoice: common.invoice,
    invoiceLineItem: 'Rechnungsposition',
    invoiceLineItems: common.invoiceLineItems,
    invoiceOrders: 'Aufträge abrechnen',
    invoices: common.invoices,
    invoiceTemplate: common.invoiceTemplate,
    invoiceTemplates: common.invoiceTemplates,
    leave: common.leave,
    leaveAccount: common.leaveAccount,
    leaveAgreement: 'Urlaubsvereinbarung',
    leaveAgreements: common.leaveAgreements,
    leaves: common.leaves,
    order: common.order,
    orderAnalysis: common.orderAnalysis,
    orders: common.orders,
    organizationalUnit: common.organizationalUnit,
    organizationalUnits: common.organizationalUnits,
    overtime: common.overtime,
    members: common.members,
    publicHolidays: common.publicHolidays,
    recordings: common.recordings,
    registerAttendanceMonitor: 'Registrieren',
    recordedTimes: common.recordedTimes,
    requestLeave: common.requestLeave,
    scheduledBreaks: 'Geplante Pausen',
    sickNote: 'Krankmeldung',
    sickNotes: common.sickNotes,
    submitSickNote: common.submitSickNote,
    staff: common.staff,
    tasks: common.tasks,
    timeAccount: common.timeAccount,
    timeDistribution: 'Zeitverteilung',
    timeRecord: common.timeRecord,
    timeSheet: 'Stundenzettel Details',
    timeSheetEdit: 'Stundenzettel bearbeiten',
    timeSheetList: 'Stundenzettel',
    toDos: common.toDos,
    upcomingHolidays: common.upcomingHolidays,
    workingTime: common.workingTime,
    workingTimeAgreement: 'Arbeitszeitvereinbarung',
    workingTimeAgreements: common.workingTimeAgreements,
    workingWeek: common.workingWeek,
    workplace: 'Arbeitsort',
    workplaces: 'Arbeitsorte',
    workplaceCreate: 'Arbeitsort anlegen',
  },
  button: {
    accept: 'Akzeptieren',
    activate: 'Aktivieren',
    add: 'Hinzufügen',
    approve: 'Genehmigen',
    back: 'Zurück',
    cancel: 'Stornieren',
    cancelPinInput: 'Abbrechen',
    chatbotSources: 'Quellen',
    chooseEssentials: 'Essentials wählen',
    chooseProfessional: 'Professional wählen',
    clockIn: common.clockIn,
    clockOut: common.clockOut,
    close: 'Schließen',
    connectWithDatev: 'Mit DATEV verbinden',
    create: common.create,
    deactivate: 'Deaktivieren',
    decrement: 'Reduzieren',
    delete: 'Löschen',
    disable: 'Deaktivieren',
    distribute: common.distribute,
    done: 'Fertig',
    download: common.download,
    downloadTimeSheetExportConfigurationFile: 'DATEV-Konfigurationsdatei herunterladen',
    edit: 'Speichern',
    enable: 'Aktivieren',
    enter: 'Betreten',
    export: 'Exportieren',
    hideFeatures: 'Features verbergen',
    import: 'Importieren',
    importStaff: 'Mitarbeiter importieren',
    increment: 'Erhöhen',
    invite: 'Einladen',
    invoice: 'Abrechnen',
    invoiceOrders: 'Aufträge abrechnen',
    leaveOrganization: 'Verlassen',
    letterhead: 'Briefpapier verwenden',
    letsGo: 'Los geht\'s',
    logout: common.logout,
    markAsDefault: 'Als Standard auswählen',
    next: 'Weiter',
    passOrganizationOwner: 'Absenden',
    printPdf: 'Drucken (PDF)',
    register: 'Registrieren',
    reject: 'Ablehnen',
    remove: 'Entfernen',
    reopen: 'Wiedereröffnen',
    request: 'Beantragen',
    resend: 'Erneut senden',
    reset: 'Zurücksetzen',
    retryDatevExport: 'Export zu DATEV',
    revoke: 'Zurücknehmen',
    revokeAuthorization: 'DATEV-Verbindung trennen',
    save: 'Speichern',
    saveAndNext: 'Speichern und weiter',
    showFeatures: 'Features anzeigen',
    signIn: common.signIn,
    signUp: common.signUp,
    skip: 'Überspringen',
    start: 'Start',
    startNow: 'Loslegen',
    stop: 'Stopp',
    submit: 'Einreichen',
    switchToEssentials: 'Zu Essentials wechseln',
    switchToProfessional: 'Zu Professional wechseln',
    timeSheet: 'Stundennachweis',
    tryAgain: 'Erneut versuchen',
    turnOff: 'Ausblenden',
  },
  checkbox: {
    commentRequired: 'Kommentar erforderlich',
    halfDay: 'Halber Tag',
    newsletter: 'Ich möchte regelmäßig über Neuigkeiten zu ZeitFabrik informiert werden.',
    privacy: 'Ich stimme den {0} zu.',
    tac: 'Mit der Geltung der {0} der CodeFabrik GmbH für die Nutzung von ZeitFabrik bin ich einverstanden.',
  },
  enum: {
    accepted: 'Genehmigt',
    active: 'Aktiv',
    all: common.all,
    allOrders: 'Alle Aufträge',
    closedOrdersOnly: 'Nur geschlossene Aufträge',
    created: 'Angelegt',
    distributable: common.distributable,
    distributed: common.distributed,
    employee: common.employee,
    inactive: 'Inaktiv',
    invited: 'Eingeladen',
    leaveType: {
      overtime_compensation: 'FFÜ',
      regular: common.leave,
      special: 'Sonderurlaub',
    },
    new: 'Neu',
    openOrdersOnly: 'Nur offene Aufträge',
    overbooked: 'Überbucht',
    pending: 'Ausstehend',
    rejected: 'Abgelehnt',
    supervisor: common.supervisor,
    submitted: 'Eingereicht',
  },
  fileName: {
    timeSheet: 'Stundenzettel',
    timeRecords: 'Zeiterfassungen',
  },
  help: {
    authenticationFailed: 'Bitte überprüfe deine Zugangsdaten.',
    createStaffAnnualLeaveTargetLeave: 'Der Urlaubsanspruch pro Jahr.',
    createStaffDatesContractEnd: 'Der Vertrag gilt als unbefristet wenn kein Datum eingetragen wird',
    createStaffDatesContractStart: 'Das Einstellungsdatum von {firstName}.',
    createStaffDatesStartUsage: 'Ab welchem Datum soll {firstName} Zeiten erfassen?',
    csvUpload: 'Klicke auf das Feld um eine CSV-Datei auszuwählen',
    currentPassword: 'Das aktuelle Passwort wird zur Änderung des Passworts benötigt.',
    currentPasswordForPinChange: 'Das aktuelle Passwort wird zur Änderung der PIN benötigt.',
    distributedOrderTimeAlreadyInvoiced: 'Zeitverteilung wurde bereits in Rechnung gestellt.',
    distributedOrderTimeForFilterMissing: 'Es sind noch keine Auftragszeiten für diese Filtereinstellungen verteilt.',
    distributedOrderTimeInIntervalMissing: 'Es sind noch keine Auftragszeiten in dem gewählten Zeitraum verteilt.',
    deactivateStaffDisabledReasonAdmin: 'Der Mitarbeiter ist ein Administrator und kann deshalb nicht deaktiviert werden.',
    deactivateStaffDisabledReasonPendingOwner: 'Es existiert eine offene Anfrage zur Übernahme der Organisation durch diesen Mitarbeiter.',
    deleteInvoiceTemplateDisabled: 'Die Standard Rechnungsvorlage kann nicht gelöscht werden.',
    deleteLeaveDisabled: 'Es fehlt die Aufgabe zum Löschen von Urlaub.',
    deleteSickNoteDisabled: 'Es fehlt die Aufgabe zum Löschen von Krankmeldungen.',
    deleteStaffDisabled: 'Der Mitarbeiter hat verteilte Zeiten.',
    deleteTimeRecordDisabled: 'Es fehlt die Aufgabe zum Löschen von Zeiterfassungen.',
    emailIsTaken: 'E-Mail ist bereits registriert.',
    emailUsername: 'E-Mail wird als Benutzername verwendet.',
    imageUpload: 'Das Briefpapier muss als Bilddatei hochgeladen werden. Zum Beispiel .jpeg oder .png.',
    invalidPassword: 'Fehlerhaftes Passwort.',
    invoiceMessage: 'Eine optionale Nachricht an deinen Kunden.',
    invoicePartyAddress: 'Wird über dem Adresskopf deiner Rechnung angezeigt.',
    invoiceRecipient: 'Der abzurechnende Kunde.',
    invoiceRecipientAddress: 'Dies ist die Adresse des ausgewählten Kunden.',
    leaveAgreementEnd: 'Wird kein Enddatum angegeben, ist die Urlaubsvereinbarung unbefristet.',
    manageDistributedOrderTimeTaskMissing: 'Es fehlt die Aufgabe zum Verwalten von Zeitverteilungen',
    name: 'Der Name deiner Firma oder deines Teams sind geeignet.',
    organizationName: 'Der Name deiner Firma oder deines Teams sind geeignet.',
    privacy: 'Zustimmung zu den Datenschutzbestimmungen',
    receivedLeave: 'Der aus dem Vorjahr übertragene Resturlaub.',
    requestPasswordReset: 'Deine E-Mail für die Anmeldung.',
    tac: 'Zustimmung zu den allgemeinen Geschäftsbedingungen',
    takenLeave: 'Die Anzahl an bereits verwendetem Urlaub.',
    targetLeave: 'Der Sollurlaub für das laufende Jahr.',
    unsubscribeAtAnyTime: 'Du kannst dich jederzeit abmelden.',
    workingTimeAgreementEnd: 'Wird kein Enddatum angegeben, ist die Arbeitszeitvereinbarung unbefristet.',
  },
  label: {
    absent: 'Abwesend',
    address: 'Adresse',
    all: 'Keine Beschränkung',
    annualLeave: common.annualLeave,
    balance: 'Saldo',
    break: 'Pause',
    breakTime: 'Pausenzeit',
    budget: common.budget,
    calendarWeekAbbreviation: 'KW',
    change: 'Änderung',
    changedBy: 'Geändert von',
    clientNumber: 'Mandantennummer',
    clockedIn: 'Eingestempelt',
    clockedOut: 'Ausgestempelt',
    comment: common.comment,
    confirm: 'Bestätigen',
    confirmPassword: common.confirmPassword,
    connectionCreatedByDatevUser: 'Verbinung hergestellt von DATEV Benutzer:',
    consultantNumber: 'Beraternummer',
    contactPerson: common.contactPerson,
    creationDate: 'Erstellungsdatum',
    crn: 'Referenznr.',
    csv: 'CSV Datei',
    currentPassword: common.currentPassword,
    currentEdition: 'Aktuelle Edition',
    currentWeek: 'Laufende Woche',
    currentMonth: 'Laufender Monat',
    customer: common.customer,
    customerAddress: common.customerAddress,
    customerName: common.customerName,
    customerReferenceNumber: 'Referenznummer',
    date: 'Datum',
    datevClientId: 'DATEV ClientId: ',
    datevExportFormat: 'DATEV Export Format',
    datevFileStatus: 'DATEV Datei Status',
    datevLODAS: 'LODAS',
    datevLUG: 'LUG',
    datevNotificationEmailAddress: 'E-Mail Adresse für Benachrichtigungen (optional)',
    datevRefreshTokenExpiryDate: 'Ablaufdatum Refresh Token:',
    day: 'Tag',
    dayOff: 'Frei',
    days: 'Tage',
    default: 'Standard',
    description: common.description,
    deviceName: common.deviceName,
    distributedTime: 'Verteilte Zeit:',
    duration: 'Dauer',
    email: 'E-Mail',
    employee: common.employee,
    end: common.end,
    error: 'Fehler',
    firstName: 'Vorname',
    friday: 'Freitag',
    from: 'von',
    functionality: 'Anwendungsbereich',
    halfHoliday: 'Halber Feiertag',
    hello: 'Hallo',
    holiday: common.holiday,
    hourlyRate: common.hourlyRate,
    hours: 'Stunden',
    importable: 'Importierbar',
    importPossible: 'Import möglich',
    importImpossible: 'Import nicht möglich',
    importStaffIdentificationNumberAsCompanyStaffIdentificationNumber: 'Personalnummer zusätzlich als betriebliche Personalnummer importieren?',
    invoicableTime: common.invoicableTime,
    invoicedTime: common.invoicedTime,
    invoiceDate: 'Rechnungsdatum',
    invoiceDeliveryPeriod: 'Leistungszeitraum',
    invoiceMessage: 'Nachricht',
    invoiceNumber: common.invoiceNumber,
    invoicePartyAddress: 'Adresse des Rechnungsstellers',
    invoicePaymentTerms: 'Zahlungsfrist',
    invoiceRecipient: 'Rechnungsempfänger',
    invoiceRecipientAddress: 'Adresse des Rechnungsempfängers',
    invoiceTemplate: common.invoiceTemplate,
    invoicingParty: 'Rechnungssteller',
    invoicingPartyVatNumber: 'USt-IdNr. des Rechnungsstellers',
    lastName: 'Nachname',
    leave: common.leave,
    leaveHalfDay: 'Halber Tag {0}',
    leaveType: 'Art des Urlaubs',
    legallyCompliant: 'Gesetzeskonforme Zeiterfassung',
    letterhead: 'Briefpapier',
    monday: 'Montag',
    month: 'Monat',
    months: 'Monate',
    name: common.name,
    newPassword: common.newPassword,
    newPin: 'Neue PIN',
    number: common.number,
    of: 'von',
    order: common.order,
    orderName: common.orderName,
    organization: common.organization,
    organizationalUnit: common.organizationalUnit,
    overtime: common.overtime,
    overtimeAccount: 'Überstundenkonto',
    page: 'Seite',
    password: common.password,
    percent: 'Prozent',
    present: 'Anwesend',
    price: 'Brutto',
    priceBeforeTax: 'Netto',
    processedAt: 'Bearbeitet am',
    processedBy: 'Bearbeitet von',
    totalPrice: 'Bruttosumme',
    totalPriceBeforeTax: 'Nettosumme',
    type: 'Art',
    quantity: 'Menge',
    question: 'Frage',
    reason: 'Begründung',
    recordedWorkingTime: 'Erfasste Arbeitszeit',
    remainingLeave: 'Resturlaub',
    role: common.role,
    saturday: 'Samstag',
    search: 'Suchen',
    staff: common.staff,
    staffIdentificationNumber: 'Personalnummer',
    start: common.start,
    status: common.status,
    sick: 'Krank',
    sixMonths: 'Sechs Monate',
    sunday: 'Sonntag',
    supervisor: common.supervisor,
    takenLeave: common.takenLeave,
    targetLeave: common.targetLeave,
    targetWorkingTime: 'Sollarbeitszeit',
    thursday: 'Donnerstag',
    time: common.time,
    timeRecord: common.timeRecord,
    timeSheet: 'Stundennachweis',
    to: 'bis',
    transferredLeave: 'Urlaubsübertrag',
    tuesday: 'Dienstag',
    unitPrice: 'Stückpreis',
    upcomingLeave: 'Geplanter Urlaub',
    vat: common.vat,
    vatNumber: 'USt-IdNr.',
    wednesday: 'Mittwoch',
    week: 'Woche',
    workingTime: common.workingTime,
    year: 'Jahr',
    years: 'Jahre',
    yearCycle: 'Jahresrhythmus',
    priceTable: 'Edition',
    currentLicense: 'Aktuelle Edition'
  },
  link: {
    absences: common.absences,
    account: 'Benutzerkonto',
    accountingSettings: 'Abrechnungseinstellungen',
    attendanceMonitors: common.attendanceMonitors,
    audits: common.audits,
    back: 'Zurück',
    connect: 'Connect',
    controlling: 'Controlling',
    cookieConsent: 'Cookie-Einwilligung',
    createOrganization: 'Organisation anlegen',
    customerAnalysis: common.customerAnalysis,
    customers: common.customers,
    datev: 'DATEV',
    datevConnectedApplications: 'Verbundene Anwendungen verwalten',
    datevHelpPage: 'DATEV Hilfe',
    demo: 'Lieber eine Demo buchen',
    distribute: common.distribute,
    distributedTimes: common.distributedTimes,
    distributions: common.distributions,
    done: 'Erledigt',
    download: common.download,
    edit: 'Bearbeiten',
    faq: 'FAQ',
    firstSteps: 'Erste Schritte',
    forgotPassword: common.forgotPassword,
    goToOptions: 'Zu den Optionen',
    help: 'Hilfe',
    helpCenter: 'Helpcenter besuchen',
    here: 'hier',
    imprint: 'Impressum',
    invoice: common.invoice,
    invoices: common.invoices,
    invoiceLineItems: common.invoiceLineItems,
    invoiceTemplates: common.invoiceTemplates,
    leaveAccount: common.leaveAccount,
    leaveAgreements: common.leaveAgreements,
    leaveAndTimeAccounts: common.leaveAndTimeAccounts,
    leaves: common.leaves,
    logout: common.logout,
    manageLicense: 'Lizenz verwalten',
    manageOrganization: 'Organisation verwalten',
    members: common.members,
    more: 'Mehr',
    orderAnalysis: common.orderAnalysis,
    orders: common.orders,
    organization: 'Organisation',
    organizationalUnits: common.organizationalUnits,
    organizations: common.organizations,
    overtime: common.overtime,
    privacy: 'Datenschutzbestimmungen',
    privacyFooter: 'Datenschutz',
    profile: 'Profil',
    publicHolidays: common.publicHolidays,
    recordedTimes: common.recordedTimes,
    recordings: common.recordings,
    scheduledBreaks: 'Geplante Pausen',
    security: common.security,
    sickNotes: common.sickNotes,
    signIn: common.signIn,
    signUp: common.signUp,
    staff: common.staff,
    tac: 'allgemeinen Geschäftsbedingungen',
    tacFooter: 'AGB',
    tasks: common.tasks,
    timeAccount: common.timeAccount,
    timeIntervalExport: 'Zeiterfassungsexport',
    times: 'Zeiten',
    timeSheet: 'Stundenzettel',
    upcomingHolidays: common.upcomingHolidays,
    workplaces: 'Arbeitsorte',
    workingTime: common.workingTime,
    workingTimeAgreements: common.workingTimeAgreements,
    workingTimeLaw: 'Arbeitszeitgesetz',
    workingTimeLawChecks: 'Überprüfungen'
  },
  notification: {
    adminPrivilegesAdded: 'Adminrechte wurden erfolgreich hinzugefügt.',
    adminPrivilegesRemoved: 'Adminrechte wurden erfolgreich entfernt.',
    attendanceMonitorDeleted: 'Anwesenheitsmonitor wurde erfolgreich gelöscht.',
    attendanceMonitorEdited: 'Anwesenheitsmonitor wurde erfolgreich gespeichert.',
    attendanceMonitorRegistered: 'Anwesenheitsmonitor wurde erfolgreich registriert.',
    breakScheduled: 'Pause erfolgreich eingeplant.',
    customerCreated: 'Kunde wurde erfolgreich angelegt.',
    customerDeleted: 'Kunde wurde erfolgreich gelöscht.',
    customerEdited: 'Kunde wurde erfolgreich gespeichert.',
    datevAuthorizationRevoked: 'DATEV Autorisierung erfolgreich zurückgenommen.',
    datevConfigurationCreated: 'DATEV Konfiguration wurde erfolgreich angelegt.',
    datevConfigurationEdited: 'DATEV Konfiguration wurde erfolgreich bearbeitet.',
    datevExportActivated: 'Export nach DATEV wurde erfolgreich aktiviert.',
    datevExportDeactivated: 'Export nach DATEV wurde erfolgreich deaktiviert.',
    distributedOrderTimeDeleted: 'Zeitverteilung wurde erfolgreich gelöscht.',
    distributedOrderTimeEdited: 'Zeitverteilung wurde erfolgreich bearbeitet.',
    edited: 'Benutzer wurde erfolgreich gespeichert.',
    germanWorkingTimeLawChecksDisabled: 'Automatische Überprüfung des Arbeitszeitgesetz deaktiviert.',
    germanWorkingTimeLawChecksEnabled: 'Automatische Überprüfung des Arbeitszeitgesetz aktiviert.',
    holidayCreated: 'Feiertag wurde erfolgreich angelegt.',
    holidayDeleted: 'Feiertag wurde erfolgreich gelöscht.',
    invitationAccepted: 'Einladung erfolgreich angenommen.',
    invitationDeclined: 'Einladung erfolgreich abgelehnt.',
    invitationEmailSent: 'Einladungs-E-Mail erfolgreich versandt.',
    invitationRevoked: 'Einladung erfolgreich zurückgenommen.',
    invoiceCreated: 'Rechnung wurde erfolgreich erstellt.',
    invoiceEdited: 'Rechnung wurde erfolgreich gespeichert.',
    invoiceDeleted: 'Rechnung wurde erfolgreich gelöscht.',
    invoiceLineItemCreated: 'Rechnungsposition wurde erfolgreich angelegt.',
    invoiceLineItemEdited: 'Rechnungsposition wurde erfolgreich gespeichert.',
    invoiceLineItemDeleted: 'Rechnungsposition wurde erfolgreich gelöscht.',
    invoiceOrdersInvoiced: 'Aufträge wurden erfolgreich in Rechnung gestellt.',
    invoiceTemplateCreated: 'Rechnungsvorlage wurde erfolgreich angelegt.',
    invoiceTemplateDeleted: 'Rechnungsvorlage wurde erfolgreich gelöscht.',
    invoiceTemplateEdited: 'Rechnungsvorlage wurde erfolgreich gespeichert.',
    invoiceTemplateMarkedAsDefault: 'Rechnungsvorlage wurde erfolgreich als Standard ausgewählt.',
    leaveAccepted: 'Urlaub wurde erfolgreich genehmigt.',
    leaveAccountEdited: 'Urlaubskonto wurde erfolgreich gespeichert.',
    leaveAgreementCreated: 'Urlaubsvereinbarung erfolgreich angelegt.',
    leaveAgreementEdited: 'Urlaubsvereinbarung erfolgreich gespeichert.',
    leaveAgreementDeleted: 'Urlaubsvereinbarung erfolgreich gelöscht.',
    leaveCreated: 'Urlaub wurde erfolgreich erstellt.',
    leaveDeleted: 'Urlaub wurde erfolgreich gelöscht.',
    leaveEdited: 'Urlaub wurde erfolgreich bearbeitet.',
    leaveRejected: 'Urlaub wurde erfolgreich abgelehnt.',
    mailResent: 'Email wurde erfolgreich versandt.',
    memberRemoved: 'Mitglied erfolgreich entfernt.',
    membersAdded: 'Mitglieder erfolgreich hinzugefügt.',
    manualTimeSheetExportToDatevSuccessful: 'Export successful',
    orderClosed: 'Auftrag wurde erfolgreich geschlossen.',
    orderCreated: 'Auftrag wurde erfolgreich erstellt.',
    orderDeleted: 'Auftrag wurde erfolgreich gelöscht.',
    orderEdited: 'Auftrag wurde erfolgreich gespeichert.',
    orderReopened: 'Auftrag wurde erfolgreich wiedereröffnet.',
    organizationalUnitCreated: 'Organisationseinheit erfolgreich angelegt.',
    organizationalUnitDeleted: 'Organisationseinheit erfolgreich gelöscht.',
    organizationalUnitEdited: 'Organisationseinheit erfolgreich gespeichert.',
    organizationCreated: 'Organisation wurde erfolgreich angelegt.',
    organizationEdited: 'Organisation wurde erfolgreich gespeichert.',
    organizationDeleted: 'Organisation wurde erfolgreich gelöscht.',
    organizationLeft: 'Du hast die Organisation erfolgreich verlassen.',
    organizationLicenseChanged: 'Die Lizenz wurde erfolgreich geändert.',
    organizationOwnershipAccepted: 'Antrag zur Übernahme der Organisationsführung angenommen.',
    organizationOwnershipDeclined: 'Antrag zur Übernahme der Organisationsführung abgelehnt.',
    overtimeDecremented: 'Überstunden wurden erfolgreich reduziert.',
    overtimeIncremented: 'Überstunden wurden erfolgreich erhöht.',
    passOrganizationOwnerRequestCreated: 'Antrag zur Übergabe der Organisationsführung erstellt.',
    passOrganizationOwnerRequestRevoked: 'Antrag zur Übergabe der Organisationsführung zurückgezogen.',
    passwordChanged: 'Passwort wurde erfolgreich geändert.',
    pinChanged: 'PIN wurde erfolgreich geändert.',
    passwordReset: 'Passwort wurde erfolgreich zurückgesetzt.',
    passwordResetRequested: 'E-Mail zum Zurücksetzen des Passworts wurde versand.',
    publicHolidaysReplaced: 'Gesetzliche Feiertage erfolgreich gespeichert.',
    sickNoteCreated: 'Krankmeldung wurde erfolgreich erstellt.',
    sickNoteSubmitted: 'Krankmeldung wurde erfolgreich eingereicht.',
    sickNoteDeleted: 'Krankmeldung wurde erfolgreich gelöscht.',
    sickNoteAccepted: 'Krankmeldung wurde erfolgreich akzeptiert.',
    sickNoteRejected: 'Krankmeldung wurde erfolgreich abgelehnt.',
    sickNoteEdited: 'Krankmeldung wurde erfolgreich bearbeitet.',
    staffClockedIn: 'Kommen-Buchung erfolgreich.',
    staffClockedOut: 'Gehen-Buchung erfolgreich.',
    staffCreated: 'Personal erfolgreich angelegt.',
    staffDeactivated: 'Personal erfolgreich deaktiviert.',
    staffDeleted: 'Personal erfolgreich gelöscht.',
    staffEdited: 'Personal erfolgreich gespeichert.',
    staffExcluded: 'Personal erfolgreich von Prüfungen ausgenommen.',
    staffImported: 'Mitarbeiter erfolgreich importiert',
    staffIncluded: 'Personal erfolgreich zu Prüfungen hinzugefügt.',
    taskTypesReplaced: 'Aufgaben erfolgreich gespeichert.',
    timeDistributed: 'Zeit wurde erfolgreich verteilt.',
    timeRecordCanceled: 'Zeiterfassung wurde erfolgreich storniert.',
    timeRecordCreated: 'Zeiterfassung wurde erfolgreich angelegt.',
    timeRecordDeleted: 'Zeiterfassung wurde erfolgreich gelöscht.',
    timeRecordEdited: 'Zeiterfassung wurde erfolgreich bearbeitet.',
    timeSheetCreated: 'Stundenzettel wurde erfolgreich angelegt',
    timeSheetEdited: 'Stundenzettel wurde erfolgreich bearbeitet',
    timeSheetDeleted: 'Stundenzettel wurde erfolgreich gelöscht',
    timeSheetSubmitted: 'Stundenzettel wurde erfolgreich eingereicht',
    timeSheetAccepted: 'Stundenzettel wurde erfolgreich freigegeben',
    timeSheetRejected: 'Stundenzettel wurde erfolgreich abgelehnt',
    workingTimeAgreementCreated: 'Arbeitszeitvereinbarung erfolgreich angelegt.',
    workingTimeAgreementDeleted: 'Arbeitszeitvereinbarung erfolgreich gelöscht.',
    workingTimeAgreementEdited: 'Arbeitszeitvereinbarung erfolgreich gespeichert.',
    workplaceCreated: 'Arbeitsort erfolgreich angelegt.',
    workplaceDisabled: 'Arbeitsort erfolgreich deaktiviert.',
    workplaceEnabled: 'Arbeitsort erfolgreich aktiviert.',
  },
  paragraph: {
    absenceOverview: 'Übersicht Fehlzeiten',
    allowedWorkingTimeExceeded: 'Maximal zulässige Arbeitszeit von 10 Stunden um {duration} überschritten.',
    averageWorkingTimeLimitExceeded: 'Tägliche Arbeitszeit im Durchschnitt (letzte 24 Wochen) um {duration} über 8 Stunden.',
    breakTimeInsufficientSixToNineHours: 'Ruhepausen in Summe {duration} weniger als 30 Minuten bei Arbeitszeit zwischen 6 und 9 Stunden. Es werden gemäß gesetzlicher Bestimmungen nur Pausen von mindestens 15 Minuten Länge gezählt.',
    breakTimeInsufficientNinePlusHours: 'Ruhepausen in Summe {duration} weniger als 45 Minuten bei Arbeitszeit von mehr als 9 Stunden. Es werden gemäß gesetzlicher Bestimmungen nur Pausen von mindestens 15 Minuten Länge gezählt.',
    licenseMissing: 'Leider besitzt die Organisation {organizationName} keine gültige Lizenz.',
    alreadyUserHint: 'Bereits registriert?',
    chatbotInitialWelcomeMessage: 'Hallo, wie kann ich dir behilflich sein?',
    chatbotNotAvailable: 'Der Chatbot ist derzeit nicht verfügbar. Bitte versuche es später erneut.',
    chooseFunctionalityBasic: 'Arbeitszeiten erfassen.',
    chooseFunctionalityAdvanced: 'Arbeitszeiten erfassen und Zeitkonto inkl. Urlaubsantrag und Krankmeldung verwenden.',
    clockedInSince: 'Eingestempelt seit:',
    clockedTimeForCurrentDay: 'Zeit',
    configureOrganization: 'Je nachdem, wie du ZeitFabrik nutzen möchtest, nehmen wir für Dich einige Einstellungen vor. Du kannst Dich jederzeit umentscheiden und ZeitFabrik anpassen.',
    customerAnalysis: 'Kundenanalyse',
    customerCreate: 'Leg {0} einen Kunden an.',
    customerManagement: 'Kundenverwaltung',
    datevAuthorizationNotPresent: 'Um automatisierte Workflows zwischen ZeitFabrik und DATEV zu ermöglichen, musst du ZeitFabrik mit DATEV verbinden. Die Verbindung ist derzeit nicht aktiv.',
    datevAuthorizationPending: 'DATEV Autorisierung läuft. Bitte warten...',
    datevAuthorizationPresent: 'ZeitFabrik ist erfolgreich mit DATEV verbunden.',
    datevAuthorizationSuccessful: 'Autorisierung erfolgreich. Du wirst in {countdown} Sekunden automatisch weitergeleitet.',
    datevConfiguration: 'Die DATEV Konfiguration ist vorhanden.',
    datevConfigurationMissing: 'Um die DATEV-Anbindung nutzen zu können, musst du zuerst eine Konfiguration anlegen.',
    datevExportActive: 'Automatischer Export von Stundenzetteln an DATEV aktiv.',
    datevExportDeactivated: 'Aktiviere den automatischen Export von Stundenzetteln, damit jeder akzeptierte Stundenzettel automatisch zu DATEV exportiert wird.',
    datevTimeSheetExportFailed: 'Der automatische DATEV Export des Stundenzettels ist fehlgeschlagen. Mit dem unten stehenden Button kannst du den Export manuell auslösen. Sollte der Export weiterhin fehlschlagen, findest Du weitere Hilfe in der Knowledge Base: {0}',
    days: 'Tage',
    distributable: common.distributable,
    distributableMoreToDistribute: '...und weitere',
    distributableNothingToDistribute: 'Prima, alle Zeiten sind verteilt.',
    distributed: common.distributed,
    duration: '{duration} Stunden',
    emailSupport: 'E-Mail Support',
    enterPin: 'Bitte gib deine PIN ein',
    enterYourEmail: 'Trag deine E-Mail ein.',
    exemptFromAudit: 'Diese Person ist von den Überprüfungen ausgenommen. Du kannst die Überprüfungen in den Optionen wieder aktivieren, in dem Du diese Person aus der Liste der ausgenommenen entfernst.',
    exemptFromAuditSupervisor: 'Diese Person ist von den Überprüfungen ausgenommen. Ein Administrator kann die Überprüfungen in den Optionen wieder aktivieren, in dem er diese Person aus der Liste der ausgenommenen entfernt.',
    exportToDATEV: 'DATEV-Export',
    features1: 'Beinhaltet',
    features2: 'alle Features von Essentials',
    features3: 'plus:',
    germanWorkingTimeLawChecks: {
      checksDisabled: 'Überprüfungen deaktiviert',
      descriptionBreakTimesCheck:'ZeitFabrik überwacht, dass die notwendigen Pausenzeiten eingehalten werden (§4 ArbZG).',
      descriptionDailyWorkingTimeCheck:'ZeitFabrik überwacht, dass die tägliche Höchstarbeitszeit eingehalten wird (§3 ArbZG).',
      descriptionRestPeriodsCheck: 'ZeitFabrik überwacht, dass die Ruhezeit zwischen zwei Arbeitstagen eingehalten wird (§5 ArbZG).',
      descriptionTimeRecordingNotPresentCheck: 'ZeitFabrik überwacht, dass Arbeitszeiten erfasst werden, d.h. dass zu jedem Kalendertag mit einer positiven Sollarbeitszeit mindestens eine Zeiterfassung existiert (erfordert die Nutzung des Zeitkontos mit Arbeitszeitvereinbarung, Urlaub, Krankmeldung und Feiertagen).',
      headingBreakTimesCheck: 'Überprüfung der Pausenzeiten',
      headingDailyWorkingTimeCheck: 'Überprüfung der Arbeitszeiten',
      headingRestPeriodsCheck: 'Überprüfung der Ruhezeiten',
      headingTimeRecordingNotPresentCheck: 'Überprüfung der Zeiterfassung',
      summary: 'ZeitFabrik überprüft für dich, dass die Regelungen des Arbeitszeitgesetzes (ArbZG) eingehalten werden.',
      summaryDisabled: 'Die Überprüfung der Regelungen des Arbeitszeitgesetzes (ArbZG) sind deaktiviert.',
      youAreSafe: 'Du bist sicher',
    },
    invitationError: 'Die Eingaben stimmen nicht mit der Einladung überein. Bitte überprüfe den Link und versuche es erneut.',
    invitationExpired: 'Die Einladung ist abgelaufen. Bitte wende dich an die Person, die dich eingeladen hat.',
    invoiceCustomerMissing: 'Rechnungen benötigen einen Kunden.',
    invoicing: 'Rechnungserstellung',
    invoiceOrdersMissing: 'Der Kunde hat keine Aufträge. Für die Abrechnung müssen Zeiten auf Aufträge verteilt werden.',
    invoiceTemplates: 'Rechnungsvorlagen',
    lawViolation: 'Prüfung nach ArbZG',
    leaveAgreementMissing: 'Jeder braucht mal eine Pause. Nenn uns deinen Urlaubsanspruch, damit wir dir immer sagen können, wie viele Urlaubstage du noch hast.',
    leaveRequest: 'Urlaubsantrag',
    leaveRequestToDoDescription: 'Beantworte den Urlaubsantrag von {firstName} {lastName}.',
    letterhead: 'Briefpapier',
    noStaffImportable: 'Keine importierbaren Mitarbeiter gefunden.',
    noOrdersFound: 'Keine Aufträge gefunden.',
    notAnAdmin: 'Nur Administratoren dürfen die Details von  {firstName} {lastName} einsehen.',
    orderAnalysis: 'Auftragsanalyse',
    orderCreate: 'Leg {0} einen Auftrag an.',
    orderManagement: 'Auftragsverwaltung',
    orderMissing: 'Zeit kann nur auf aktive Aufträge, die deiner Organisationseinheit zugeordnet sind, verteilt werden.',
    organizationalUnits: 'Organisationseinheiten',
    organizationalUnitManagement: 'Verwaltung Organisationseinheiten',
    organizationOwnershipToDoDescription: 'Beantworte den Antrag auf Übernahme der Organisationsführung.',
    overtimeBalance: 'Aktueller Stand Überstundenkonto:',
    perUserMonth: 'Pro Nutzer / Monat zzgl. MwSt.',
    restTimeInsufficient: 'Mindestruhezeit von 11 Stunden zwischen zwei Arbeitstagen um {duration} unterschritten.',
    sickNote: 'Krankmeldung',
    sickNoteSubmissionToDoDescription: 'Bearbeite die Krankmeldung von {firstName} {lastName}.',
    specialLeaveRequestToDoDescription: 'Beantworte den Sonderurlaubsantrag von {firstName} {lastName}.',
    overtimeCompensationLeaveRequestToDoDescription: 'Beantworte den FFÜ-Antrag von {firstName} {lastName}.',
    staffManagement: 'Personalverwaltung',
    taskEditDisabled: 'Einige Aufgaben können nicht bearbeitet werden, weil Vertrauensarbeitszeit aktiviert ist. Gehe zum dashboard, wenn du die Vertrauensarbeitszeit deaktivieren möchtest.',
    timeAccount: 'Zeitkonto',
    timeDistribution: 'Zeitverteilung',
    timeLeft: 'Verbleibende Zeit',
    timeRecordingNotPresent: 'Zeiterfassung fehlt (Sollarbeitszeit: {duration}).',
    timeSheet: 'Stundennachweis',
    timeSheetToDoSubscription: 'Bearbeite den Stundenzettel von {firstName} {lastName}.',
    timeTracking: 'Zeiterfassung',
    trustBasedFlexTimeDisabled: 'Vertrauensarbeitszeit deaktiviert',
    trustBasedFlexTimeEnabled: '✌️ Vertrauensarbeitszeit aktiviert ✌️',
    trustBasedFlexTimeExplained: 'Wenn Vertrauensarbeitszeit aktiviert ist, können Vorgesetzte die Arbeitszeiten der Mitarbeiter:Innen nicht sehen, werden aber weiterhin über Verstöße gegen das Arbeitszeitgesetz informiert.',
    workedFromTo: 'von {0} bis {1} gearbeitet ({2} Stunden)',
    workedFrom: '{0} von {1} Stunden gearbeitet',
    workingTimeAgreementMissing: 'Du erfasst deine Zeiten mit ZeitFabrik. Trage deine Arbeitszeiten ein und wir pflegen automatisch dein Arbeitszeitkonto für dich.',
    workingTimeOverview: 'Übersicht Arbeitszeiten',
    newHint: 'Neu bei ZeitFabrik?',
    notifySupervisor: 'Bitte informiere deinen Vorgesetzten.',
    rememberedPassword: 'Passwort wieder eingefallen?'
  },
  placeholder: {
    all: common.all,
    annualLeave: common.annualLeave,
    budget: common.budget,
    confirmPassword: common.confirmPassword,
    contactPerson: common.contactPerson,
    contractOpenEnded: 'Unbefristet',
    currentPassword: common.currentPassword,
    customerAddress: common.customerAddress,
    customerName: common.customerName,
    customerReferenceNumber: 'Referenznummer',
    description: common.description,
    deviceName: common.deviceName,
    durationFormat: '00:00',
    email: 'E-Mail',
    end: common.end,
    firstName: 'Max',
    holidayName: 'Feiertag',
    holidayYearCycle: 'Jahresrhythmus',
    hourlyRate: common.hourlyRate,
    invoiceMessage: 'Danke für die Zusammenarbeit.',
    invoicePartyAddress: 'Unternehmen | Straße | Stadt',
    invoicePaymentTerms: 'Zahlbar innerhalb von 14 Tagen.',
    invoiceTemplateName: 'Rechnungsvorlage #1',
    invoicingPartyVatNumber: 'USt-IdNr.',
    lastName: 'Mustermann',
    name: 'Name der Organisation',
    newPassword: common.newPassword,
    newPin: 'Neuer PIN',
    noResults: 'Keine Ergebnisse.',
    number: common.number,
    numberZero: '0,0',
    orderName: common.orderName,
    organizationAddress: 'Addresse deiner Organisation',
    organizationDeleteSafetyText: 'Gib den Namen deiner Organisation an, um sie löschen zu können',
    organizationName: 'Name deiner Organisation',
    organizationVatNumber: 'USt-IdNr. deiner Organisation',
    organizationalUnit: common.organizationalUnit,
    organizationalUnitName: 'Z.B. Personalabteilung',
    password: common.password,
    pleaseSelect: 'Bitte wählen',
    quantity: '1,00',
    searchMember: 'Nach Mitgliedern suchen',
    selectAStaff: 'Mitarbeiter auswählen',
    selectDayOfWeek: 'Wochentag auswählen',
    selectOrder: 'Auftrag auswählen',
    selectOrganizationalUnit: 'Organisationseinheit auswählen',
    selectTime: 'Zahl eingeben',
    staff: common.staff,
    start: common.start,
    unitPrice: '100,99 €',
    username: 'Benutzername',
    vat: common.vat,
  },
  subtitle: {
    acceptInvitation: 'Bitte melde dich an um die Einladung anzunehmen.',
    addAdminPrivileges: 'Ein Administrator kann alle Aspekte einer Organisation einsehen und verändern. Nur ausgewählte Benutzer sollten Adminrechte erhalten.',
    answerLeaveRequest: 'Nach Akzeptieren des Urlaubs wird das Urlaubs- und Zeitkonto des Mitarbeiters aktualisiert.',
    answerPassOrganizationOwnershipRequest: 'Der aktuelle Besitzer der Organisation hat Dich gebeten, die Führung der Organisation zu übernehmen. Dafür musst Du den AGB zustimmen. Du hast danach zum Beispiel die Rechte, die Organisation zu löschen.',
    answerSickNote: 'Nach Akzeptieren der Krankmeldung wird das Urlaubs- und Zeitkonto des Mitarbeiters aktualisiert.',
    assignColumnHeaders: 'Weise die Spaltenüberschriften aus deinem CSV-File den passenden Feldern in ZeitFabrik zu.',
    assignOrganizationalUnit: 'Wähle eine Organisationeinheit und eine Rolle für den Mitarbeiter.',
    cancelTimeRecord: 'Das Stornieren einer Zeiterfassung kann nicht rückgängig gemacht werden.',
    changePassword: 'Nach erfolgreicher Änderung wird zur Anmeldeseite weitergeleitet.',
    changePin: 'Die PIN kann zum Ein- und Ausstempeln mit einem Anwesenheitsmonitor verwendet werden.',
    checkAndTryAgain: 'Bitte überprüfe deine Internetverbindung und versuche es erneut.',
    chooseStaffRole: 'Wie und in welcher Rolle soll {firstName} ZeitFabrik nutzen?',
    closeOrder: 'Ein geschlossener Auftrag kann wiedereröffnet werden. Auf einen geschlossenen Auftrag können keine Zeiten verteilt werden.',
    confirm: 'Wir haben dir eine E-Mail zum Bestätigen deiner Registrierung gesendet. Klicke auf den Link in der E-Mail und du kannst beginnen ZeitFabrik zu nutzen.',
    confirmEmailLogin: 'Bitte melde dich an um deine E-Mail-Adresse zu bestätigen.',
    createOrganizationStaffName: 'So werden andere Benutzer in deiner Organisation dich erkennen können.',
    createStaffAnnualLeave: 'Wieviele jährliche Urlaubstage stehen {firstName} zu?',
    createStaffDates: 'Für die Berechnung der Zeit- und Urlaubskonten von {firstName} benötigen wir die Vertragsdaten und den Verwendungsbeginn von ZeitFabrik.',
    createStaffLeaveAccount: 'Nach der Einrichtung wird das Konto von uns automatisch aktualisiert.',
    createStaffLeaveAccountReceivedLeave: 'Gibt es noch nicht genutzte Urlaubstage aus den Vorjahren?',
    createStaffLeaveAccountTakenLeave: 'Wieviele Tage Urlaub, die nicht in ZeitFabrik erfasst wurden, hat {firstName} in diesem Jahr bereits genommen?',
    createStaffName: 'Der Name des Mitarbeiters wird an verschiedenen Stellen in deiner Organisation angezeigt.',
    createStaffSummary: 'Mitarbeiter {firstName} {lastName} jetzt anlegen.',
    createStaffTimeAccount: 'Nach der Einrichtung wird das Konto von uns automatisch aktualisiert.',
    createStaffWorkingTimes: 'Stell die täglichen Arbeitszeiten von {firstName} ein.',
    deactivateStaff: 'Setzt den Status auf inaktiv. Der Benutzer wird von diesem Mitarbeiter entfernt. Du kannst nach der Deaktivierung einen neuen Benutzer einladen.',
    deleteAccount: 'Achtung: Hiermit löscht du deinen Benutzer in ZeitFabrik. Diesen Schritt kannst du nicht rückgängig machen! Solange du noch Teil einer Organisation bist kannst du dein Benutzerkonto nicht löschen.',
    deleteAttendanceMonitor: 'Gelöschte Anwesenheitsmonitore können nicht wiederhergestellt werden!',
    deleteCustomer: 'Gelöschte Kunden können nicht wiederhergestellt werden!',
    deleteCustomerRequirement: 'Ein Kunde kann nur gelöscht werden, wenn er nicht von einem Auftrag oder einer Rechnung referenziert wird.',
    deleteDistributedOrderTime: 'Gelöschte Zeitverteilungen können nicht wiederhergestellt werden!',
    deleteHoliday: 'Feiertag wird ab sofort entfernt. Gesetzliche Feiertage können über den Menüpunkt \'Fehlzeiten - Gesetzliche Feiertage\' wiederhergestellt werden.',
    deleteInvoice: 'Abgerechnete Zeiten werden zur erneuten Abrechnung freigegeben.',
    deleteInvoiceLineItem: 'Alle in Rechnung gestellten Zeiten werden wieder für die Abrechnung freigegeben.',
    deleteInvoiceTemplate: 'Gelöschte Rechnungsvorlagen können nicht wiederhergestellt werden!',
    deleteLeave: 'Gelöschte Urlaube können nicht wiederhergestellt werden!',
    deleteLeaveAgreement: 'Das Löschen einer Urlaubsvereinbarung führt zu einer Neuberechnung des Urlaubskontos in dem entsprechenden Zeitraum.',
    deleteOrder: 'Gelöschte Aufträge können nicht wiederhergestellt werden!',
    deleteOrderRequirement: 'Ein Auftrag kann nur gelöscht werden, wenn er noch keine verteilten Zeiten hat.',
    deleteOrganizationalUnit: 'Gelöschte Organisationseinheiten können nicht wiederhergestellt werden!',
    deleteStaff: 'Gelöschte Mitarbeiter können nicht wiederhergestellt werden!',
    deleteStaffRequirement: 'Ein Mitarbeiter kann nur gelöscht werden, wenn er keine verteilten Zeiten hat.',
    deleteSickNote: 'Gelöschte Krankmeldungen können nicht wiederhergestellt werden!',
    deleteTimeRecord: 'Gelöschte Zeiterfassungen können nicht wiederhergestellt werden!',
    deleteTimeSheet: 'Gelöschte Stundenzettel können nicht wiederhergestellt werden!',
    deleteWorkingTimeAgreement: 'Das Löschen einer Arbeitszeitvereinbarung führt zu einer Neuberechnung des Arbeitszeitkontos in dem entsprechenden Zeitraum.',
    disableWorkplace: 'Hier kannst du diesen Arbeitsort deaktivieren. Es kann sich dann niemand mehr auf diesen Arbeitesort einstempeln. Du kannst den Arbeitsort wieder aktivieren, wenn du deine Meinung änderst.',
    disableGermanWorkingTimeLawChecks: 'Wenn du die Überprüfung nicht verwenden möchtest, kannst du diese hier deaktivieren. Du kannst die Überprüfung jederzeit wieder aktivieren.',
    downloadInvoice: 'Der Briefpapier muss im DIN A4 Format sein, um korrekt dargestellt werden zu können.',
    editLeaveAccount: 'Bitte beachte, dass das Urlaubskonto automatisch berechnet wird. Bearbeite das Urlaubskonto nur, um Änderungen wiederzuspiegeln, die in ZeitFabrik nicht erfasst sind.',
    editLeaveAgreement: 'Das Bearbeiten einer Urlaubsvereinbarung führt zu einer Neuberechnung des Urlaubskontos in dem entsprechenden Zeitraum.',
    editOvertime: 'Die Überstunden werden wöchentlich berechnet. Zusätzlich kannst du die Überstunden frei anpassen.',
    email: 'Zum Erstellen eines Accounts wird eine E-Mail-Adresse benötigt.',
    emailConfirmed: 'Vielen Dank für die Bestätigung deiner E-Mail-Adresse. Du kannst ZeitFabrik jetzt nutzen!',
    enableGermanWorkingTimeLawChecks: 'Aktiviere die automatische Überprüfung.',
    enableWorkplace: 'Hier kannst du diesen Arbeitsort aktivieren. Man kann sich dann auf diesen Arbeitesort einstempeln. Du kannst den Arbeitsort wieder deaktivieren, wenn du deine Meinung änderst.',
    excludePersons: 'Lege fest für welche Personen keine Prüfungen ausgeführt werden sollen.',
    exportTimeRecords: 'Hier kannst Du Zeiterfassungen als CSV-Datei exportieren, um sie z.B. in anderen Systemen weiter zu verwenden.',
    forgotPassword: 'Gib deine E-Mail an und wir senden dir einen Link mit einer Anleitung zum Zurücksetzen deines Passworts.',
    hoursPerCustomerPerYear: 'Jeder Balken stellt die jährlich verteilte Zeit pro Kunde dar.',
    hoursPerCustomerInLastThreeMonths: 'Jeder Balken stellt die monatlich verteilte Zeit der letzten drei Monate pro Kunde dar.',
    hoursPerCustomerRelativeShareInLastTwelveMonths: 'Jede Linie stellt den monatlichen Anteil von verteilten Zeiten der letzten zwölf Monate pro Kunde dar.',
    hoursPerOrder: 'Jeder Balken stellt die verteilten Zeiten in der ausgewählten Aggregationsstufe pro Auftrag dar. Über die obigen Filter können die angezeigten Daten angepasst werden.',
    importStaff: 'Folgende Mitarbeiter wurden in der Datei gefunden und auf ihre Importierbarkeit überprüft.',
    invitationAccepted: 'Du kannst deine Zeiten für die Organisation {organizationName} jetzt erfassen.',
    invitationDeclined: 'Wir haben die Organisation über deine Absage informiert. Vielen Dank, dass du dir Zeit dafür genommen hast. Du kannst die Seite jetzt schließen.',
    inviteUser: 'Lade eine Person ein, um diesen Mitarbeiter zu aktivieren.',
    leaveOrganization: 'Vorsicht: Diesen Schritt kannst du nicht rückgängig machen!',
    deleteOrganization: 'Vorsicht: Diesen Schritt kannst du nicht rückgängig machen! Alle mit der Organisation verknüpften Aufträge, erfasste Zeiten, Rechnungen usw. werden unwiederbringlich gelöscht.',
    markInvoiceTemplateAsDefault: 'Bestehende Standard Rechnungsvorlagen verlieren diesen Status!',
    noEmailReceived: 'Klicke unten und wir senden sie erneut.',
    organization: 'Betritt deine Organisation und erfasse deine Zeiten.',
    passOrganizationOwner: 'Hiermit kannst Du ein anderes Mitglied der Organisation bitten, die Führung der Organisation zu übernehmen. Dafür muss die Person den AGB zustimmen. Die Person hat danach die Rechte, die Organisation zu löschen. Du hast diese Rechte danach nicht mehr und kannst sie selbständig nicht zurückerlangen. Den Antrag kannst Du nur stellen, wenn noch kein anderer Antrag auf Übertragung der Organisationsführung in dieser Organisation vorliegt. Einen gestellten Antrag kannst Du zurückziehen, sofern er noch nicht angenommen wurde.',
    password: 'Ein sicheres Passwort ist einzigartig und besteht aus mindestens sechs Zeichen.',
    provideFile: 'Stelle eine csv-Datei mit einer Kopfzeile bereit, welche die Spaltenüberschriften "Vorname", "Nachname" und "E-Mail" enthält. Die Spalten müssen durch ein Semikolon (";") getrennt werden.',
    removeAdminPrivileges: 'Die Adminrechte des Benutzers werden entfernt. Sensitive Daten und Einstellungen der Organisation können nicht mehr eingesehen oder verändert werden.',
    reopenOrder: 'Auf einen wiedereröffneten Auftrag können wieder Zeiten verteilt werden.',
    resetPassword: 'Erstelle ein neues Passwort. Nach der Bestätigung kannst du dich direkt mit dem neuen Passwort anmelden.',
    revokeInvitation: 'Nachdem die Einladung erfolgreich zurückgenommen wurde, kann erneut ein Benutzer eingeladen werden.',
    revokePassOrganizationOwner: 'Hiermit wird die Abgabe der Organisationsführung an ein anderes Mitglied zurückgenommen. Du kannst danach die Abgabe der Organisationsführung erneut durchführen.',
    selectOrganizationalUnit: 'Wähle die Organisationseinheit aus, der die importierten Mitarbeiter zugeordnet werden sollen.',
    setWorkingTimes: 'Die geplante Sollarbeitszeit für jeden Tag der Woche.',
    staffsWithoutDatevCompliantIdentificationNumber: 'Stundenzettel von Personal ohne DATEV-konforme Personalnummer können nicht nach DATEV exportiert werden.',
    workingTimeAgreementDescription: 'Das Anlegen einer Arbeitszeitvereinbarung ermöglicht die automatisierte Berechnung von Überstunden im jeweiligen Gültigkeitszeitraum.',
    workingTimeAgreementFurtherDescription: 'Die Berechnung der Überstunden erfolgt am Ende einer Woche anhand der Gegenüberstellung erfasster Arbeitszeiten und den jeweiligen Sollarbeitszeiten.',
  },
  title: {
    assignColumnHeaders: 'Spaltenüberschriften zuordnen',
    email: 'Email',
    pricingTable: 'Edition auswählen',
    licenseMissing: 'Keine gültige Lizenz',
    absences: common.absences,
    accepting: 'Nehme Einladung an...',
    acceptInvitation: 'Einladung annehmen',
    activateAutomaticTimesheetDatexExport: '3. Automatischen Stundenzettel-Export zu DATEV aktivieren',
    addAdminPrivileges: 'Adminrechte hinzufügen',
    answerLeaveRequest: 'Urlaubsantrag beantworten',
    answerPassOrganizationOwnershipRequest: 'Annahme der Organisationführung',
    answerSickNote: 'Krankmeldung beantworten',
    assignOrganizationalUnit: 'Organisationseinheit zuweisen',
    attendanceMonitors: common.attendanceMonitors,
    audits: 'Überprüfungen',
    cancelTimeRecord: 'Zeiterfassung stornieren',
    changePassword: 'Passwort ändern',
    changePin: 'PIN ändern',
    chatbotSources: 'Quellen des Chatbots',
    checkResult: 'Prüfergebnis',
    chooseStaffRole: 'Anwendungsbereich und Rolle festlegen',
    clockIn: common.clockIn,
    clockOut: common.clockOut,
    closeOrder: 'Auftrag schließen',
    comment: common.comment,
    configureGermanWorkingTimeLaw: 'Arbeitszeitgesetz',
    configureOrganization: 'Organisation konfigurieren',
    confirm: 'Prüfe dein Postfach!',
    confirming: 'Bestätige E-Mail...',
    connectZeitFabrikWithDatev: '2. ZeitFabrik mit DATEV verbinden',
    contactPerson: 'Kontaktperson',
    contract: 'Vertrag',
    contractDetails: 'Vertragsdaten',
    contractOpenEnded: 'unbefristet',
    create: common.create,
    createCustomer: 'Kunde anlegen',
    createDatevConfiguration: '1. DATEV Konfiguration anlegen',
    createHoliday: 'Feiertag anlegen',
    createInvoice: 'Rechnung anlegen',
    createInvoiceLineItem: 'Rechnungsposition anlegen',
    createInvoiceTemplate: 'Rechnungsvorlage erstellen',
    createLeave: 'Urlaub anlegen',
    createLeaveAgreement: 'Urlaubsvereinbarung anlegen',
    createOrder: 'Auftrag anlegen',
    createOrganizationalUnit: 'Organisationseinheit anlegen',
    createOrganizationName: 'Wie lautet der Name deiner Organisation oder deines Teams?',
    createOrganizationStaffName: 'Trage deinen Namen ein.',
    createSickNote: 'Krankmeldung anlegen',
    createStaffAnnualLeave: 'Zeit für Erholung',
    createStaffDates: 'Wichtige Zeitpunkte festlegen',
    createStaffDatesStartUsage: 'Verwendungsbeginn von ZeitFabrik',
    createStaffLeaveAccount: 'Urlaubskonto in die ZeitFabrik übertragen',
    createStaffLeaveAccountReceivedLeave: 'Resturlaub aus den Vorjahren',
    createStaffLeaveAccountTakenLeave: 'Bereits genommener Urlaub',
    createStaffName: 'Beginnen wir mit dem Namen',
    createStaffSummary: 'Sind alle Daten korrekt?',
    createStaffTimeAccount: 'Überstunden in die ZeitFabrik übernehmen',
    createStaffWorkingTimes: 'Tägliche Arbeitszeiten eintragen',
    createTimeRecord: 'Zeiterfassung anlegen',
    createTimeSheet: 'Stundenzettel anlegen',
    createWorkingTimeAgreement: 'Arbeitszeitvereinbarung anlegen',
    createWorkplace: 'Arbeitsort anlegen',
    customer: common.customer,
    customerAnalysis: common.customerAnalysis,
    customerMissing: 'Es fehlt ein Kunde',
    customers: common.customers,
    dashboard: 'Dashboard',
    date: 'Datum',
    datevExport: 'DATEV-Export',
    datevConfigurationMissing: 'Fehlende DATEV Konfiguration',
    dayOfWeek: 'Wochentag',
    daysOfLeave: 'Urlaubstage',
    deactivateStaff: 'Personal deaktivieren',
    declining: 'Lehne Einladung ab...',
    decrementOvertime: 'Überstunden reduzieren',
    defineAValidity: 'Gültigkeitszeitraum definieren',
    deleteAccount: 'Benutzerkonto löschen',
    deleteAttendanceMonitor: 'Anwesenheitsmonitor löschen',
    deleteCustomer: 'Kunde löschen',
    deleteDistributedOrderTime: 'Zeitverteilung löschen',
    deleteHoliday: 'Feiertag löschen',
    deleteInvoice: 'Rechnung löschen',
    deleteInvoiceLineItem: 'Rechnungsposition löschen',
    deleteInvoiceTemplate: 'Rechnungsvorlage löschen',
    deleteLeave: 'Urlaub löschen',
    deleteLeaveAgreement: 'Urlaubsvereinbarung löschen',
    deleteOrder: 'Auftrag löschen',
    deleteOrganizationalUnit: 'Organisationseinheit löschen',
    deleteSickNote: 'Krankmeldung löschen',
    deleteStaff: 'Personal löschen',
    deleteTimeRecord: 'Zeiterfassung löschen',
    deleteTimeSheet: 'Stundenzettel löschen',
    deleteWorkingTimeAgreement: 'Arbeitszeitvereinbarung löschen',
    description: common.description,
    deviceName: common.deviceName, disableGermanWorkingTimeLawChecks: 'Überprüfung deaktivieren',
    disableWorkplace: 'Arbeitsort deaktivieren',
    distributableTime: 'Verteilbare Zeit',
    distributeTime: 'Zeit verteilen',
    distributedTimes: common.distributedTimes,
    distributions: common.distributions,
    downloadInvoice: 'Rechnung herunterladen',
    downloadTimeSheet: 'Stundennachweis herunterladen',
    editAttendanceMonitor: 'Anwesenheitsmonitor bearbeiten',
    editCustomer: 'Kunde bearbeiten',
    editDatevConfiguration: 'DATEV Konfiguration bearbeiten',
    editInvoice: 'Rechnung bearbeiten',
    editInvoiceLineItem: 'Rechnungsposition bearbeiten',
    editInvoiceTemplate: 'Rechnungsvorlage bearbeiten',
    editLeave: 'Urlaub bearbeiten',
    editLeaveAccount: 'Urlaubskonto bearbeiten',
    editLeaveAgreement: 'Urlaubsvereinbarung bearbeiten',
    editOrder: 'Auftrag bearbeiten',
    editOrganization: 'Organisation bearbeiten',
    editOrganizationalUnit: 'Organisationseinheit bearbeiten',
    editOvertime: 'Überstunden bearbeiten',
    editSickNote: 'Krankmeldung bearbeiten',
    editStaff: 'Personal bearbeiten',
    editTimeRecord: 'Zeiterfassung bearbeiten',
    editTimeDistribution: 'Zeitverteilung bearbeiten',
    editTimeSheet: 'Stundenzettel bearbeiten',
    editWorkingTimeAgreement: 'Arbeitszeitvereinbarung bearbeiten',
    emailConfirmed: 'Willkommen in der ZeitFabrik.',
    enabled: 'Aktiviert',
    enableGermanWorkingTimeLawChecks: 'Überprüfung aktivieren',
    enableWorkplace: 'Arbeitsort aktivieren',
    end: common.end,
    essentials: 'Essentials',
    excludePersons: 'Personen ausschließen',
    exemptFromAudit: 'Von der Überprüfung ausgenommen',
    exportTimeRecords: 'Zeiterfassungen exportieren',
    forgotPassword: common.forgotPassword,
    hoursPerCustomerPerYear: 'Stunden pro Kunde pro Jahr',
    hoursPerCustomerInLastThreeMonths: 'Stunden pro Kunde pro Monat',
    hoursPerCustomerRelativeShareInLastTwelveMonths: 'Relativer Anteil verteilter Zeiten pro Kunde pro Monat',
    hoursPerOrder: 'Stunden pro Auftrag',
    importStaff: 'Mitarbeiter importieren',
    incrementOvertime: 'Überstunden erhöhen',
    invitationAccepted: 'Einladung angenommen.',
    invitationDeclined: 'Einladung abgelehnt.',
    invitationError: 'Einladung fehlerhaft',
    invitationExpired: 'Einladung abgelaufen',
    invitationTo: 'Einladung an',
    inviteUser: 'Benutzer einladen',
    invoicableTime: common.invoicableTime,
    invoice: common.invoice,
    invoiceDate: 'Rechnungsdatum',
    invoiceOrdersMissing: 'Es fehlen Aufträge',
    invoicedTime: common.invoicedTime,
    invoiceLineItems: common.invoiceLineItems,
    invoiceOrders: 'Aufträge abrechnen',
    invoiceNumber: common.invoiceNumber,
    invoices: common.invoices,
    invoiceTemplates: common.invoiceTemplates,
    lawViolation: 'Prüfung nach Arbeitszeitgesetz',
    leave: common.leave,
    leaveAccount: common.leaveAccount,
    leaveAgreementMissing: 'Urlaubskonto benutzen',
    leaveAgreements: common.leaveAgreements,
    leaveAndTimeAccounts: common.leaveAndTimeAccounts,
    leaveOrganization: 'Organisation verlassen',
    deleteOrganization: 'Organisation löschen',
    leaves: common.leaves,
    markInvoiceTemplateAsDefault: 'Rechnungsvorlage als Standard auswählen',
    member: 'Mitglied',
    members: common.members,
    name: common.name,
    noEmailReceived: 'Keine Email bekommen?',
    noInternetConnection: 'Keine Internetverbindung.',
    notAnAdmin: 'Keine Adminrechte',
    number: common.number,
    optional: 'Optional',
    order: common.order,
    orderAnalysis: common.orderAnalysis,
    orderMissing: 'Es fehlt ein Auftrag',
    orders: common.orders,
    orderStopWatch: 'Auftragsstoppuhr',
    organization: common.organization,
    organizationalUnit: common.organizationalUnit,
    organizationalUnits: common.organizationalUnits,
    organizations: common.organizations,
    overtime: common.overtime,
    overtimeAccount: 'Überstundenkonto',
    overtimeRecords: 'Überstunden',
    passOrganizationOwner: 'Organisationsführung abgeben',
    password: 'Sicherheit ist wichtig.',
    period: 'Zeitraum',
    professional: 'Professional',
    provideFile: 'Datei hochladen',
    publicHolidays: common.publicHolidays,
    receivedLeave: 'Urlaubsübertrag',
    recipient: 'Empfänger',
    recordedTimes: common.recordedTimes,
    recordings: common.recordings,
    registerAttendanceMonitor: 'Anwesenheitsmonitor registrieren',
    registrationHelp: 'Können wir Dir helfen?',
    remainingLeave: common.remainingLeave,
    removeAdminPrivileges: 'Adminrechte entfernen',
    reopenOrder: 'Auftrag wiedereröffnen',
    requestLeave: common.requestLeave,
    resetPassword: 'Passwort zurücksetzen',
    revokeInvitation: 'Einladung zurücknehmen',
    revokePassOrganizationOwner: 'Abgabe der Organisationsführung zurücknehmen',
    role: common.role,
    createScheduledBreak: 'Pause planen',
    scheduledBreaks: 'Geplante Pausen',
    security: common.security,
    selectOrganizationalUnit: 'Organisationseinheit auswählen',
    setWorkingTimes: 'Arbeitszeiten einstellen',
    sickNote: common.sickNote,
    sickNotes: common.sickNotes,
    signIn: common.signIn,
    slogan: 'Einfach.Mehr.Zeit',
    staff: common.staff,
    staffsWithoutDatevCompliantIdentificationNumber: 'Personal ohne DATEV-konforme Personalnummer',
    start: common.start,
    status: common.status,
    submitSickNote: common.submitSickNote,
    takenLeave: common.takenLeave,
    targetLeave: common.targetLeave,
    tasks: common.tasks,
    time: common.time,
    timeAccount: common.timeAccount,
    timeClock: 'Stempeluhr',
    timeRecord: common.timeRecord,
    timeSheet: 'Stundenzettel',
    toDos: common.toDos,
    trustBasedFlexTime: 'Vertrauensarbeitszeit',
    upcomingHolidays: common.upcomingHolidays,
    userAccount: 'Benutzerkonto',
    workplaces: 'Arbeitsorte',
    workingTime: common.workingTime,
    workingTimeAgreementMissing: 'Zeitkonto benutzen',
    workingTimes: 'Arbeitszeiten',
    workingTimeAgreements: common.workingTimeAgreements,
    workingTimeLawChecks: 'Überprüfungen',
    yourOrganizations: 'Deine Organisationen',
    zfUsageStart: 'Verwendungsbeginn von ZeitFabrik',
  },
  backend: {
    datev: {
      error: {
        invalidDatevRequest: 'Fehler in der Kommunikation zu DATEV.'
      }
    },
    networkError: 'ZeitFabrik ist nicht erreichbar.',
    illegalAccess: 'Zugriff verweigert.',
    attendanceMonitor: {
      error: {
        invalidPin: 'Du hast eine falsche PIN eingegeben.',
        deviceNameIsTaken: 'Ein Gerät mit diesem Namen wurde bereits registriert.',
        maximumExceeded: 'Du hast die maximal erlaubte Anzahl der Anwesenheitsmonitore erreicht und kannst keinen neuen anlegen. Wende dich an unseren Support, wenn du weitere Anwsenheitsmonitore benötigst.',
      }
    },
    invitation: {
      error: {
        userIsAlreadyAMember: 'Du bist bereits Mitglied dieser Organisation.',
        expired: 'Einladung ist abgelaufen.'
      }
    },
    scheduledBreak: {
      error: {
        intersecting: 'Die Geplante Pause schneidet eine bestehende Geplante Pause.'
      }
    },
    timeInterval: {
      error: {
        endIsBeforeOrEqualStart: 'Zwischen Ein- und Ausstempeln muss mindestens eine Minute vergehen.',
        endIsBeforeStart: 'Start muss vor dem Ende liegen.',
        tooLong: 'Das Intervall überschreitet die maximale Länge.',
        intervalIsIntersectingAnotherInterval: 'Der Eintrag schneidet ein bestehendes Interval.'
      }
    },
    timeRecord: {
      error: {
        recordAtTimeAlreadyPresent: 'Ein Eintrag am \'{time}\' existiert bereits.',
        recordIsIntersectingAnInterval: 'Der Eintrag schneidet ein bestehendes Interval.',
        recordWithSameTypeAdjacent: 'Ein benachbarteter Eintrag hat den selben Typen.',
        invalidFirstEntry: 'Der erste Eintrag darf keine Gehen-Buchung sein.'
      }
    },
    timeAccount: {
      error: {
        durationNegativeOrZero: 'Der eingegebene Wert muss größer als 0 sein.',
      }
    },
    distributedOrderTime: {
      error: {
        orderTimeIsZero: 'Zeit muss größer als 0 sein.'
      }
    },
    leave: {
      error: {
        endIsBeforeStart: 'Start muss vor dem Ende liegen.',
        leaveAlreadyPresent: 'Der Zeitraum überschneidet sich mit einem bereits erfassten Urlaub.'
      }
    },
    sickNote: {
      error: {
        endIsBeforeStart: 'Start muss vor dem Ende liegen.',
        sickNoteAlreadyPresent: 'Der Zeitraum überschneidet sich mit einer bereits erfassten Krankmeldung.'
      }
    },
    staffImportValidation: {
      error: {
        fileIsEmpty: 'Die bereitgestellte Datei ist leer',
        tooFewColumns: 'Die Datei enthält zu wenig Spalten',
      }
    },
    publicHoliday: {
      'Good Friday': 'Karfreitag',
      'Epiphany': 'Heilige Drei Könige',
      'Womens Day': 'Frauentag',
      'Corpus Christi': 'Fronleichnam',
      'Peace Festival': 'Friedensfest',
      'Assumption Day': 'Mariä Himmelfahrt',
      'All Saints': 'Allerheiligen',
      'Repentance and Prayer Day': 'Buß- und Bettag',
      'Easter Monday': 'Ostermontag',
      'Labor Day': 'Tag der Arbeit',
      'Ascension Day': 'Christi Himmelfahrt',
      'Whit Monday': 'Pfingstmontag',
      'German Unity Day': 'Tag der Deutschen Einheit',
      'Reformation Day': 'Reformationstag',
      'Christmas Eve': 'Heiligabend',
      'Christmas Day': '1. Weihnachtsfeiertag',
      'Boxing Day': '2. Weihnachtsfeiertag',
      'New Years Eve': 'Silvester',
      'New Years Day': 'Neujahr'
    },
    staff: {
      error: {
        staffWithEmailAlreadyExists: 'In dieser Organisation gibt es bereits einen Mitarbeiter mit dieser E-Mail.',
        onlyInvitationForInvitedStaffCanBeAccepted: 'Einladung ist abgelaufen.',
        invitationIdDoesNotMatch: 'Einladung ist abgelaufen.',
        staffForMeAlreadyExists: 'Du bist bereits Mitarbeiter in dieser Organisation.'
      }
    },
    workingTimeAgreement: {
      error: {
        workingTimeAgreementAlreadyPresent: 'Der Zeitraum überschneidet sich mit einer bereits erfassten Arbeitszeitvereinbarung.',
        leaveAlreadyPresent: 'In dem angegebenen Zeitraum ist breits Urlaub genehmigt. Lösche erst den Urlaub.',
        workingTimesImmutableDueToOvertimeCompensation: 'Arbeitzeiten sind wegen genehmigten FFÜ Anträgen innerhalb des Arbeitszeitraumes nicht veränderbar.'
      }
    },
    leaveAgreement: {
      error: {
        leaveAgreementAlreadyPresent: 'Der Zeitraum überschneidet sich mit einer bereits erfassten Urlaubsvereinbarung.',
      }
    },
    organizationalUnit: {
      error: {
        nameIsTaken: 'Name ist bereits vergeben.',
        nameIsBlank: 'Name darf nicht leer sein.',
        isOrganization: 'Organisation kann nicht gelöscht werden.'
      }
    },
    device: {
      error: {
        deviceNameIsTaken: 'Gerätenummer ist bereits vergeben.',
        descriptionIsTaken: 'Beschreibung ist bereits vergeben.'
      }
    },
    order: {
      error: {
        canNotDeleteOrderWithSpentTime: 'Der Auftrag kann aufgrund verteilter Zeiten nicht gelöscht werden.',
        commentRequired: 'Ein Kommentar ist erforderlich.'
      }
    },
    customer: {
      error: {
        customerAlreadyPresent: 'Dieser Name ist bereits vergeben.',
        canNotDeleteCustomerWithOrders: 'Der Kunde kann auf Grund bestehender Aufträge nicht gelöscht werden.',
        canNotDeleteCustomerWithInvoices: 'Der Kunde kann auf Grund bestehender Rechnungen nicht gelöscht werden.'
      }
    },
    invoicing: {
      creation: {
        error: {
          invoiceNumberAlreadyExists: 'Diese Rechnungsnummer ist bereits vergeben!'
        }
      }
    },
    invoice: {
      error: {
        differentContactPersonsInInvoice: 'Ausgewählte Aufträge gehören sind verschiedenen Kontaktpersonen zugeordnet.',
        badFormat: 'Die Rechnungsnummer muss im Format YYYY/XX vorliegen.',
        invoiceNumberInUse: 'Rechnungsnummer wird bereits verwendet.',
        invoiceNumberIsBlank: 'Rechnungsnummer ist leer.',
      }
    },
    invoiceTemplate: {
      error: {
        invoiceTemplateNameInUse: 'Dieser Name ist bereits vergeben.'
      }
    },
    user: {
      error: {
        hasNoPin: 'Es ist keine PIN vergeben. Setze eine PIN im Menu, um dieses Feature zu nutzen.',
        tokenResetLinkExpired: 'Der Link ist abgelaufen.',
        passwordInvalid: 'Ungültiges Passwort.',
        emailAddressInUse: 'E-Mail wird bereits verwendet.',
        illegalAccess: 'Illegaler Zugriff.',
        registrationExpired: 'Registrierung ist abgelaufen.',
        alreadyConfirmed: 'E-Mail bereits bestätigt.',
        isInOrganization: 'Du bist noch Teil einer Organisation.'
      }
    },
    timeSheetExport: {
      error: {
        datevConfigurationMissing: 'Bitte füge zuerst eine DATEV Konfiguration hinzu.',
        staffIdentificationNumberInvalid: 'Bitte vergebe eine numerische Personalnummer mit höchstens 5 Zeichen.',
      }
    },
    datevAuthorization: {
      error: {
        invalidToken: 'Das empfangene Token is ungültig. Bitte versuche es später erneut.',
        alreadyExists: 'Die Organisation ist bereits autorisiert.',
        invalidDatevRequest: 'DATEV ist aktuell nicht erreichbar. Bitte versuche es später erneut.',
      }
    },
    datevAuthorizationRequest: {
      error: {
        invalidToken: 'Das empfangene Token is ungültig. Bitte versuche es später erneut.',
        notFound: 'Ungültige Sitzung. Bitte starte eine neue Autorisierungsanfrage.',
        userDoesNotMatch: 'Ungültige Sitzung. Bitte starte eine neue Autorisierungsanfrage.',
        invalidDatevRequest: 'DATEV ist aktuell nicht erreichbar. Bitte versuche es später erneut.',
        accessDenied: 'Zugriff verweigert.',
      }
    },
    taskTypes: {
      'timeRecording': 'Zeiterfassung',
      'supervisorsClockTimeForThemselves': 'Vorgesetzte stempeln für sich selbst',
      'employeesClockTimeForThemselves': 'Mitarbeiter stempeln für sich selbst',
      'employeesManageTimeIntervalsForThemselves': 'Mitarbeiter verwalten Zeiterfassungen für sich selbst',
      'employeesSubmitTimeSheetsForThemselves': 'Mitarbeiter legen Stundenzettel für sich selbst an',
      'supervisorsManageTimeIntervalsForThemselves': 'Vorgesetzte verwalten Zeiterfassungen für sich selbst',
      'supervisorsManageTimeIntervalsForEmployees': 'Vorgesetzte verwalten Zeiterfassungen für Mitarbeiter',
      'supervisorsViewTimeAccountForEmployees': 'Vorgesetzte betrachten Zeitkonto für Mitarbeiter',
      'supervisorsViewTimeAccountForThemselves': 'Vorgesetzte betrachten Zeitkonto für sich selbst',
      'employeesViewTimeAccountForThemselves': 'Mitarbeiter betrachten Zeitkonto für sich selbst',
      'leaveAndSickNote': 'Urlaub und Krankmeldung',
      'supervisorsManageLeaveForEmployees': 'Vorgesetzte verwalten Urlaube für Mitarbeiter',
      'supervisorsManageLeaveForThemselves': 'Vorgesetzte verwalten Urlaube für sich selbst',
      'employeesRequestLeaveForThemselves': 'Mitarbeiter beantragen Urlaube für sich selbst',
      'supervisorsManageSickNoteForEmployees': 'Vorgesetzte verwalten Krankmeldungen für Mitarbeiter',
      'supervisorsManageSickNoteForThemselves': 'Vorgesetzte verwalten Krankmeldungen für sich selbst',
      'employeesSubmitSickNoteForThemselves': 'Mitarbeiter melden sich selbst krank',
      'timeDistribution': 'Zeitverteilung',
      'employeesManageDistributedTimesForThemselves': 'Mitarbeiter verwalten Zeitverteilungen für sich selbst',
      'supervisorsManageDistributedTimesForThemselves': 'Vorgesetzte verwalten Zeitverteilungen für sich selbst',
      'supervisorsManageDistributedTimesForEmployees': 'Vorgesetzte verwalten Zeitverteilungen für Mitarbeiter',
      'supervisorsManageTimeSheetsForThemselves': 'Vorgesetzte verwalten Stundenzettel für sich selbst',
      'supervisorsManageTimeSheetsForEmployees': 'Vorgesetzte verwalten Stundenzettel für Mitarbeiter',
      'customerAndInvoice': 'Kunden und Rechnungen',
      'supervisorsManageCustomers': 'Vorgesetzte verwalten Kunden',
      'employeesManageCustomers': 'Mitarbeiter verwalten Kunden',
      'supervisorsManageInvoices': 'Vorgesetzte verwalten Rechnungen',
      'employeesManageInvoices': 'Mitarbeiter verwalten Rechnungen',
      'organization': 'Organisation',
      'supervisorsManageAttendanceMonitors': 'Vorgesetzte verwalten Anwesenheitsmonitore',
      'employeesManageAttendanceMonitors': 'Mitarbeiter verwalten Anwesenheitsmonitore',
      'report': 'Berichte',
      'supervisorsEvaluateWorkingTime': 'Vorgesetzte werten Arbeitszeiten aus',
      'employeesEvaluateWorkingTime': 'Mitarbeiter werten Arbeitszeiten aus',
      'supervisorsEvaluateAbsences': 'Vorgesetzte werten Fehlzeiten aus',
      'employeesEvaluateAbsences': 'Mitarbeiter werten Fehlzeiten aus',
      'employeesViewLeaveAndTimeAccounts': 'Mitarbeiter betrachten Stand der Urlaubs- und Zeitkonten',
      'supervisorsViewLeaveAndTimeAccounts': 'Vorgesetzte betrachten Stand der Urlaubs- und Zeitkonten',
      'analysis': 'Analysen',
      'supervisorsAnalyzeOrders': 'Vorgesetzte analysieren Aufträge',
      'employeesAnalyzeOrders': 'Mitarbeiter analysieren Aufträge',
      'supervisorsAnalyzeCustomers': 'Vorgesetzte analysieren Kunden',
      'employeesAnalyzeCustomers': 'Mitarbeiter analysieren Kunden',
      'supervisorsViewWorkingTimeLawViolationsForThemselves': 'Vorgesetzte betrachten Überprüfungen des ArbZG für sich selbst',
      'supervisorsViewWorkingTimeLawViolationsForEmployees': 'Vorgesetzte betrachten Überprüfungen des ArbZG für Mitarbeiter',
      'employeesViewWorkingTimeLawViolationsForThemselves': 'Mitarbeiter betrachten Überprüfungen des ArbZG für sich selbst',
      'supervisorsViewScheduledBreaksForThemselves': 'Vorgesetzte betrachten geplante Pausen für sich selbst',
      'supervisorsViewScheduledBreaksForEmployees': 'Vorgesetzte betrachten geplante Pausen für Mitarbeiter',
      'employeesViewScheduledBreaksForThemselves': 'Mitarbeiter betrachten geplante Pausen für sich selbst',

    },
    organization: {
      error: {
        canNotDelete: 'Die Organisation konnte nicht gelöscht werden.',
        requestAlreadyPending: 'Es ist bereits ein Antrag auf Übernahme der Organisationsführung gestellt.',
        requesterIsNotOwner: 'Der Antragsteller hat nicht die Führung der Organisation.',
        accepterIsNotNewOwner: 'Der Antrag richtet sich nicht an die Person, die ihn anzunehmen versucht.',
        userIsNotAnAdmin: 'Der Benutzer ist kein Admin.',
        userIsNotAnAdminOrOwner: 'Der Benutzer ist kein Admin oder Besitzer.'
      }
    },
    payment: {
      success: 'Die Erstellung des Kontos wurde erfolgreich abgeschlossen.'
    },
    timesheet: {
      error: {
        nameNotUnique: 'Der Name wird bereits verwendet.',
        endBeforeStart: 'Das Startdatum muss vor dem Enddatum liegen.',
        cannotRetrieveJobStatus: 'Dateistatus konnte nicht angefragt werden.',
        invalidJobStatus: 'Dateistatus unbekannt.',
        timeSheetContainsDayWithoutWorkingTimeAgreement: 'Jeder Tag des Monats muss durch eine Arbeitszeitvereinbarung abgedeckt sein.'
      },
      trackingStatus: {
        UPLOADED: 'Hochgeladen',
        IMPORTED: 'Importiert',
        CORRUPTED: 'Beschädigt',
        DELETED: 'Gelöscht',
        AUTO_DELETED: 'Automatisch gelöscht',
      }
    },
    workplace: {
      error: {
        nameTaken: 'Der Name ist bereits vergeben'
      }
    }
  },
  overtimeRecord: {
    type: {
      INITIAL: 'Initialer Eintrag',
      WORKING_WEEK: 'Abrechnung Arbeitswoche',
      ADJUSTMENT_MANUAL: 'Händische Anpassung',
      ADJUSTMENT_SYSTEM: 'Anpassung durch das System',
      OVERTIME_COMPENSATION_LEAVE: 'Frei für Überstunden',
    }
  },
  staffImport: {
    importableState: {
      IMPORTABLE: 'Importierbar',
      FIRST_NAME_MISSING: 'Vorname fehlt.',
      LAST_NAME_MISSING: 'Nachname fehlt',
      EMAIL_DUPLICATE_IN_FILE: 'E-Mail doppelt in Import-Datei',
      EMAIL_INVALID: 'E-Mail ungültig',
      EMAIL_ALREADY_REGISTERED: 'E-Mail bereits registriert',
    }
  },
  timeSheet: {
    status: {
      CREATED: 'angelegt',
      SUBMITTED: 'eingereicht',
      ACCEPTED: 'freigegeben',
      REJECTED: 'abgelehnt'
    },
    entry: {
      comment: {
        REGULAR_LEAVE: 'Urlaub',
        OVERTIME_COMPENSATION_LEAVE: 'Frei für Überstunden',
        SPECIAL_LEAVE: 'Sonderurlaub',
        SICK_DAY: 'Krankheit',
        HOLIDAY: 'Feiertag'
      }
    }
  },
  chatbot: {
    origin: {
      TIME_RECORDING: 'Zeiterfassungen von',
      SICK_NOTE: 'Krankmeldungen von',
      LEAVE: 'Urlaube von',
      LAW: 'Arbeitszeitgesetz',
      KNOWLEDGE_BASE: 'Knowledge Base'
    }
  }
}
