const initialState = {
    organizationId: undefined,
    chatId: undefined,
    messages: [],
    pendingMessage: undefined,
    loading: false,
    open: false,
    input: undefined,
    unreadMessage: false
}

const chatbotModule = {
    namespaced: true,
    state: { ...initialState },
    mutations: {
        appendSourceToPendingMessage(state, source) {
          if (!state.pendingMessage) {
            state.pendingMessage = { role: 'assistant', message: '', sources: [] }
          }
          state.pendingMessage.sources.push({
            contextType: source['context_type'],
            link: source['uri'],
            name: source['name'],
            origin: source['origin']
          })
        },
        appendContentToPendingMessage(state, content) {
          if (!state.pendingMessage) {
            state.pendingMessage = { role: 'assistant', message: '', sources: [] }
          }
          state.pendingMessage.message += content
        },
        solidifyPendingMessage(state) {
          state.messages ||= []
          state.messages.push(state.pendingMessage)
          state.unreadMessage = !state.open
          state.pendingMessage = undefined
        },
        removePendingMessage(state) {
          state.pendingMessage = undefined
        },
        addMessage(state, message) {
            state.messages ||= []
            state.messages.push(message)
            state.unreadMessage = !state.open
        },
        setMessages(state, messages) {
            state.messages = messages
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setOpen(state, open) {
            state.open = open
            if (open) {
              state.unreadMessage = false
            }
        },
        setInput(state, input) {
            state.input = input
        },
        initChat(state, chatId) {
            state.chatId = chatId
        },
        resetChat(state, organizationId) {
            state.organizationId = organizationId
            state.messages = [...initialState.messages]
            state.pendingMessage = initialState.pendingMessage
            state.chatId = initialState.chatId
            state.loading = initialState.loading
            state.open = initialState.open
            state.input = initialState.input
        }
    }
}

export default chatbotModule
export { initialState }
