<template>
  <div>
    <div
      v-if="organization && organization.license === LICENSES.PROFESSIONAL"
      class="flex flex-col"
    >
      <transition
        enter-class="opacity-0"
        enter-active-class="transition-all delay-50 duration-300"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-active-class="transition-all duration-300"
        leave-to-class="opacity-0"
      >
        <form
          v-show="open"
          class="flex flex-col border rounded-lg p-4 w-full space-y-2 bg-white shadow-2xl drop-shadow-2xl sm:w-[32rem]"
          @submit.prevent="handleSubmit"
        >
          <div class="flex justify-between items-center">
            <div class="text-xl font-bold">
              Chatbot
            </div>
            <div
              class="rounded-full p-2 text-white cursor-pointer"
              :class="deletionPending ? 'bg-brand-light': 'bg-brand'"
              @click="handleChatDelete"
            >
              <svg-trash class="w-6 h-6" />
            </div>
          </div>
          <div
            class="overflow-y-auto max-h-72 border-y px-2"
          >
            <div class="flex flex-col space-y-4 py-4">
              <div
                v-if="messages.length > 0 || !loading"
                class="flex justify-between"
              >
                <chatbot-message
                  role="assistant"
                  :message="$t('paragraph.chatbotInitialWelcomeMessage')"
                />
                <div class="w-8" />
              </div>
              <div
                v-for="(message, i) in messages"
                :key="i"
              >
                <div
                  class="flex justify-between"
                  :class="(message.role === 'user' ? 'flex-row-reverse' : '') + (message.sources?.length ?? 0 > 0 ? 'mb-3' : '')"
                >
                  <chatbot-message
                    :role="message.role"
                    :message="message.message"
                    :has-sources="(message.sources?.length ?? 0) > 0"
                    @toggleSources="handleSourcesOpen(i)"
                  />
                  <div class="w-8" />
                </div>
              </div>
              <chatbot-message
                v-if="loading"
                :role="pendingMessage?.role || 'assistant'"
                :message="pendingMessage?.message || ''"
                :has-sources="(pendingMessage?.sources?.length ?? 0) > 0"
                pending
              />
            </div>
            <div
              ref="scrollTarget"
              class="flex-shrink-0"
            />
          </div>
          <div>
            <form-field
              v-slot="props"
              :label="$t('label.question')"
              class="flex-1"
              :rules="loading ? '' : 'required'"
            >
              <div class="flex items-center justify-between gap-x-2">
                <base-input
                  ref="input"
                  :value="input"
                  type="text"
                  :readonly="loading"
                  v-bind="props"
                  class="flex-1"
                  size="10"
                  :class="loading ? 'bg-gray-200' : ''"
                  @input="setInput"
                />
                <button
                  type="submit"
                  class="bg-brand text-white p-1.5 rounded-lg cursor-pointer"
                >
                  <svg-paper-airplane class="w-8 h-8" />
                </button>
              </div>
            </form-field>
          </div>
        </form>
      </transition>
      <div class="relative flex justify-end mt-2">
        <span
          v-if="unreadMessage"
          class="absolute flex h-2 w-2"
        >
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75" />
          <span class="relative inline-flex rounded-full h-2 w-2 bg-primary" />
        </span>
        <div
          class="rounded-full p-2 bg-primary text-white cursor-pointer"
          @click="toggleChat"
        >
          <svg-chat-bubble-left-right
            v-if="!open"
            class=" w-10 h-10"
          />
          <svg-x-circle
            v-else
            class=" w-10 h-10"
          />
        </div>
      </div>
    </div>
    <dialog
      ref="sourcesDialog"
      class="rounded-lg"
    >
      <div class="flex flex-col gap-y-2">
        <div class="flex items-center justify-between gap-x-2">
          <h2 class="text-xl font-bold">
            {{ $t('title.chatbotSources') }}
          </h2>
          <div
            class="rounded-full p-2 text-white cursor-pointer bg-brand"
            @click="handleSourcesClose"
          >
            <svg-x-circle class="w-6 h-6" />
          </div>
        </div>
        <div class="flex flex-col gap-y-2">
          <div
            v-for="(source, i) in openedSources"
            :key="i"
            class="flex rounded-lg p-2 bg-brand-lightest underline"
          >
            <router-link
              v-if="source.contextType === 'ZEITFABRIK'"
              :to="source.link"
              class="w-full flex gap-x-2 justify-between text-ellepsis"
              @click.native="onInternalSourceLinkClicked"
            >
              <div>
                {{ $t(`chatbot.origin.${source.origin}`) }}
                {{ source.name ?? $t('label.time_recordings') }}
              </div>
              <svg-arrow-long-right class="shrink-0" />
            </router-link>
            <a
              v-else
              :href="source.link"
              target="_blank"
              class="w-full flex gap-x-2 justify-between text-ellepsis"
            >
              <div v-if="source.origin === 'LAW'">
                {{ $t(`chatbot.origin.${source.origin}`) }}
                {{ source.name ? ` - ${$t('label.page')} ${source.name}` : '' }}
              </div>
              <div v-else>
                {{ $t(`chatbot.origin.${source.origin}`) }}
                {{ source.name ? ` - ${source.name}` : '' }}
              </div>
              <svg-arrow-top-right-on-square class="shrink-0" />
            </a>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
  import SvgChatBubbleLeftRight from '@/components/svg/svg-chat-bubble-left-right.vue'
  import { chatbotService } from '@/api'
  import { mapMutations, mapState } from 'vuex'
  import { LICENSES } from '@/api/task/taskService'
  import SvgXCircle from '@/components/svg/svg-x-circle.vue'
  import SvgTrash from '@/components/svg/svg-trash.vue'
  import SvgPaperAirplane from '@/components/svg/svg-paper-airplane.vue'
  import SvgArrowTopRightOnSquare from '@/components/svg/svg-arrow-top-right-on-square.vue'
  import SvgArrowLongRight from '@/components/svg/svg-arrow-long-right.vue'
  import ChatbotMessage from '@/components/chatbot/chatbot-message.vue'
  import { DateTime } from 'luxon'

  export default {
    components: {
      ChatbotMessage,
      SvgArrowLongRight,
      SvgArrowTopRightOnSquare,
      SvgPaperAirplane,
      SvgTrash,
      SvgXCircle,
      SvgChatBubbleLeftRight,
    },
    props: {
      organization: {
        type: Object,
        default: undefined
      },
    },
    data() {
      return {
        deletionPending: false,
        openedSourcesId: undefined
      }
    },
    watch: {
      organizationId: {
        handler: function() {
          if (this.chatbot.organizationId !== this.organizationId) {
            this.resetChat(this.organizationId)
          }
        },
        deep: true,
        immediate: true
      },
    },
    methods: {
      ...mapMutations('chatbot', ['addMessage', 'setMessages', 'setLoading', 'initChat', 'resetChat', 'setOpen', 'setInput', 'appendSourceToPendingMessage', 'appendContentToPendingMessage', 'solidifyPendingMessage', 'removePendingMessage']),
      toggleChat() {
        this.setOpen(!this.open)
      },
      clearInput() {
        this.setInput('')
      },
      scrollToLastMessage() {
        this.$nextTick().then(() => this.$refs.scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'end' }))
      },
      async handleChatDelete() {
        if (this.deletionPending) {
          return
        }
        this.deletionPending = true
        this.resetChat(this.organizationId)
        this.setMessages([])
        this.setOpen(true)
        this.setLoading(true)

        setTimeout(() => {
          this.resetChat(this.organizationId)
          this.setOpen(true)
          this.setLoading(false)
          this.deletionPending = false
        }, 500)
      },
      async handleSubmit() {
        if (this.loading || !this.organizationId || !this.input) {
          return
        }
        const message = this.input
        this.clearInput()
        let chatId = this.chatId

        try {
          this.setLoading(true)
          this.setMessages(this.messages.filter(it => it.role !== 'error'))

          if (this.messages.length > 0 && this.messages[this.messages.length - 1].role === 'user') {
            this.setMessages(this.messages.slice(0, -1))
          }
          this.addMessage({ role: 'user', message: message })

          if (!this.chatId) {
            const response = await chatbotService.createChat({ organizationId: this.organizationId })
            this.initChat(response.data.chatId)
          }

          chatId = this.chatId
          if (chatId) {
            this.scrollToLastMessage()
            await this.streamMessage(message)
          }
        } catch (error) {
          if (chatId !== this.chatId) {
            return
          }
          this.setInput(message)
          try {
            this.addMessage({ role: 'error', message: this.$t(`backend.${error[0].message}`) })
          } catch (e) {
            this.addMessage({ role: 'error', message: this.$t('paragraph.chatbotNotAvailable') })
          }
        } finally {
          if (chatId === this.chatId) {
            this.setLoading(false)
            this.removePendingMessage()
            this.scrollToLastMessage()
          }
        }
      },
      async streamMessage(message) {
        const chatId = this.chatId

        const response = await chatbotService.streamChat(this.chatId, {
          organizationId: this.organizationId,
          message: message,
          timezone: DateTime.local().zoneName
        })

        const reader = response.body?.pipeThrough(new TextDecoderStream()).getReader()
        while(chatId === this.chatId) { // Prevent message streaming, if new chat was started.
          const { value, done } = await reader.read()
          if (done) {
            this.solidifyPendingMessage()
            break
          }

          const message = value?.trim()
          if (message) {
            const chunks = this.getChunksFromMessage(message)
            chunks.forEach(this.processMessageChunk)
          }
        }
      },
      getChunksFromMessage(message) {
        // Workaround for Firefox, that sometimes merges streamed chunks into a single message
        const regex = RegExp('\}\n*\{', 'g')
        const chunks = []
        let idx = 0
        let match
        while ((match = regex.exec(message)) !== null) {
          chunks.push(message.substring(idx, match.index + 1))
          idx = regex.lastIndex - 1
        }
        chunks.push(message.substring(idx, message.length))

        return chunks.filter(it => it.trim())
      },
      processMessageChunk(chunk) {
        const json = JSON.parse(chunk)
        switch (json.type) {
        case 'source':
          this.appendSourceToPendingMessage(json.content)
          break
        case 'message':
          this.appendContentToPendingMessage(json.content)
          break
        default:
          break
        }
      },
      handleSourcesOpen(messageId) {
        this.openedSourcesId = messageId
        this.$refs.sourcesDialog.showModal()
      },
      handleSourcesClose() {
        this.$refs.sourcesDialog.close()
        this.openedSourcesId = undefined
      },
      onInternalSourceLinkClicked() {
        this.handleSourcesClose()
      }
    },
    computed: {
      LICENSES() {
        return LICENSES
      },
      ...mapState({
        chatbot: state => state.chatbot
      }),
      messages() {
        return this.chatbot?.messages ?? []
      },
      loading() {
        return this.chatbot?.loading || !!this.chatbot?.pendingMessage
      },
      pendingMessage() {
        return this.chatbot?.pendingMessage
      },
      chatId() {
        return this.chatbot?.chatId
      },
      open() {
        return this.chatbot?.open ?? false
      },
      input() {
        return this.chatbot?.input ?? ''
      },
      unreadMessage() {
        return this.chatbot?.unreadMessage ?? false
      },
      organizationId() {
        return this.organization?.id
      },
      openedSources() {
        if (!this.openedSourcesId) {
          return []
        }
        return this.chatbot?.messages[this.openedSourcesId].sources ?? []
      }
    }
  }
</script>
