<template>
  <div
    class="relative border rounded-lg p-2 w-fit"
    :class="classesForRole"
  >
    <div
      v-if="showSourcesButton"
      class="absolute right-0 -bottom-3.5 px-3 py-0.5 rounded-full bg-primary text-white text-sm cursor-pointer"
      @click="handleToggleSources"
    >
      <div class="flex items-center gap-x-1">
        {{ $t('button.chatbotSources') }}
        <svg-arrow-long-right />
      </div>
    </div>
    <div
      class="flex whitespace-pre-line"
      :class="showSourcesButton ? 'pb-3' : ''"
    >
      <loading-spinner
        v-if="pending && !message.trim()"
      />
      <div v-else>
        {{ message }}
        <span
          v-if="pending"
          class="text-brand text-xs animate-pulse"
        >
          {{ ' ●' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgArrowLongRight from '@/components/svg/svg-arrow-long-right.vue'
  import LoadingSpinner from '@/components/loading-spinner.vue'

  export default {
    components: { LoadingSpinner, SvgArrowLongRight },
    props: {
      message: {
        type: String,
        required: true
      },
      hasSources: {
        type: Boolean,
        default: false
      },
      role: { // One of ['assistant', 'user', 'error']
        type: String,
        required: true
      },
      pending: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleToggleSources() {
        this.$emit('toggleSources')
      },
    },
    computed: {
      classesForRole() {
        switch (this.role) {
        case 'assistant':
          return 'bg-brand-lightest'
        case 'error':
          return 'text-rose-600'
        default:
          return ''
        }
      },
      showSourcesButton() {
        return (this.message.length > 16 || !this.pending) && this.hasSources
      }
    },
  }
</script>

<style scoped>

</style>
