import axios from '@/services/axios/axios'
import { userManagementService } from '@/api'

const chatbotService = {
    createChat(input) {
        return axios.post(
            '/chatbot',
            input
        )
    },
    streamChat(chatId, input) {
      return fetch(`${process.env.VUE_APP_API_URL}/chatbot/${chatId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${userManagementService.getAccessToken()}`
        },
        body: JSON.stringify(input)
      })
    }
}

export default chatbotService
